import { UserRole } from 'libs/enums';
import * as pages from 'pages';
import { AuthLayout, DashboardLayout, PublicLayout } from 'layout';
import { RouteConfig, RouteNames } from './enum';

const pageRoutes: Record<RouteNames, RouteConfig> = {
    [RouteNames.ATHLETE_PUBLIC]: {
        Page: pages.AddAthletePublic,
        Layout: PublicLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.HOME]: {
        Page: pages.Home,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ADMIN,
            UserRole.ACADEMY_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.ACADEMY_COORDINATOR,
            UserRole.MEMBER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },

    [RouteNames.SCHEDULE]: {
        Page: pages.SchedulePage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.SIGNIN]: {
        Page: pages.SignIn,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.FORGET_PASSWORD]: {
        Page: pages.ForgetPassword,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.REST_PASSWORD]: {
        Page: pages.ResetPassword,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.SIGNUP]: {
        Page: pages.SignUp,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.ATHLETE]: {
        Page: pages.AthletePage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER, UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.ADD_ATHLETE]: {
        Page: pages.AddAthlete,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.TEAM]: {
        Page: pages.TeamPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER, UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.ADD_TEAM]: {
        Page: pages.AddTeamPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.ADD_COACH]: {
        Page: pages.AddCoachPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.ADD_BRANCH]: {
        Page: pages.AddBranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.ADMIN_MANAGER]: {
        Page: pages.BranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.BRANCH]: {
        Page: pages.BranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.COACH]: {
        Page: pages.CoachPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.SESSION]: {
        Page: pages.SessionPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER, UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.SESSION_UPDATE]: {
        Page: pages.SessionUpdatePage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER, UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.PROFILE]: {
        Page: pages.AdminProfile,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_COORDINATOR,
            UserRole.ACADEMY_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.FEEDBACKS]: {
        Page: pages.Feedbacks,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_COORDINATOR],
        isAuthPage: false,
    },
    [RouteNames.APPROVAL_USERS]: {
        Page: pages.ApprovalUsers,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_COORDINATOR],
        isAuthPage: false,
    },
    [RouteNames.NOTIFICATION]: {
        Page: pages.Notification,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ADMIN,
            UserRole.ACADEMY_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.ACADEMY_COORDINATOR,
            UserRole.MEMBER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.CONFIRM_SESSION]: {
        Page: pages.ConfirmSession,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.COACH],
        isAuthPage: false,
    },
};

export default pageRoutes;
