import * as Theme from './Theme';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useMemo } from 'react';
import { camelCaseToCapital } from 'libs/helpers';
import { selectControls } from 'store';
import { router } from 'routers';
import { breadCrumpPop, switchActiveTab } from 'store/controlsSlice';
import { useLocales } from 'hooks/locales';

export const BreadCrumps = () => {
    const dispatch = useDispatch<any>();
    const { breadCrumps } = useSelector(selectControls);
    const { trans, isRTL } = useLocales();
    const handleCrumbClick = (crumb: string) => {
        if (['athleteList', 'teamList', 'coachList'].includes(crumb)) {
            dispatch(switchActiveTab({ activeTab: crumb }));
            dispatch(breadCrumpPop());
            router.navigate('home', { replace: true });
        } else if (['Team Details'].includes(crumb)) {
            dispatch(breadCrumpPop());
            dispatch(breadCrumpPop());
            router.navigate('home', { replace: true });
        }
    };

    const crumps = useMemo(
        () =>
            !breadCrumps || breadCrumps?.length === 0
                ? []
                : breadCrumps.map((crump, index) => (
                      <Fragment key={`bread crump: ${index}`}>
                          <Theme.Crump
                              $isLast={index === breadCrumps?.length - 1}
                              value={camelCaseToCapital(trans(crump))}
                              variant="p"
                              onClick={() => handleCrumbClick(crump)}
                          />
                          {index !== breadCrumps?.length - 1 ? (
                              <Theme.Arrow
                                  $isRTL={isRTL}
                                  src="/assets/icons/breadcrump-icon.png"
                                  alt="breadcrump"
                              />
                          ) : (
                              <></>
                          )}
                      </Fragment>
                  )),
        [breadCrumps],
    );

    return breadCrumps && breadCrumps?.length > 1 ? <Theme.Body>{crumps}</Theme.Body> : <></>;
};
