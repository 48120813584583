import styled from 'styled-components';
import { mq } from 'theme/mq';

export const Header1Content = styled.h1`
    font-size: 30px;

    @media (${mq.tablet}) {
        font-size: 70px;
    }
`;

export const Header2Content = styled.h2`
    font-size: 40px;
    text-align: center;
`;

export const Header3Content = styled.h3``;

export const Header4Content = styled.h4``;

export const Header5Content = styled.h5``;

export const Header6Content = styled.h6``;

export const ParagraphContent = styled.p``;

export const SpanContent = styled.span``;
