import styled from 'styled-components';
import { Image as DefaultImage, Text as DefaultText } from 'components';

export const Tab = styled.div.attrs({
    className: 'flex justify-start items-center gap-3',
})<{ $isActive: boolean; $last?: boolean; $isButton: boolean }>`
    padding: 10px;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
    background-color: ${(props) =>
        props.$isActive && props.$isButton ? '#c0d330' : 'transparent'};
    margin-top: ${(props) => (props.$last ? '100px' : '0px')};
    cursor: ${(props) => (props.$isButton ? 'pointer' : 'auto')};
    width: ${(props) => (props.$isButton ? '100%' : 'fit-content')};

    &:hover {
        background-color: ${(props) => (props.$isButton ? '#c0d330' : 'transparent')};
    }
`;

export const TabIcon = styled(DefaultImage)`
    width: 25px;
    height: 25px;
    overflow: hidden;
`;

export const TabText = styled(DefaultText)`
    font-weight: bold;
    color: #fff;
`;
