import styled from 'styled-components';
import { Image as DefaultImage } from 'components';

export const Container = styled.div<{ isRTL: boolean }>`
    position: relative;
    z-index: 1005;
    ${({ isRTL }) => (isRTL ? 'direction: rtl;' : 'direction: ltr;')}
`;

export const Body = styled.aside<{ menuOpen: boolean; isRTL: boolean }>`
    width: 20vw;
    height: 100%;
    max-height: 100vh;
    background-color: #000;
    padding: 30px;
    position: fixed;
    top: 0;
    overflow-y: auto;
    ${({ isRTL }) => (isRTL ? 'right: 0;' : 'left: 0;')}
    transition: transform 0.3s ease-in-out;
    transform: ${({ menuOpen, isRTL }) =>
        menuOpen ? 'translateX(0)' : isRTL ? 'translateX(100%)' : 'translateX(-100%)'};

    > *:not(:last-child) {
        padding: 10px;
        margin-top: 10px;
    }

    // Adjust for large screens as well
    @media (min-width: 1025px) {
        width: 20vw;
        transform: ${({ menuOpen, isRTL }) =>
            menuOpen ? 'translateX(0)' : isRTL ? 'translateX(100%)' : 'translateX(-100%)'};
    }

    @media (max-width: 1024px) {
        width: 60vw;
        transform: ${({ menuOpen, isRTL }) =>
            menuOpen ? 'translateX(0)' : isRTL ? 'translateX(100%)' : 'translateX(-100%)'};
    }

    @media (max-width: 767px) {
        width: 60vw;
        transform: ${({ menuOpen, isRTL }) =>
            menuOpen ? 'translateX(0)' : isRTL ? 'translateX(100%)' : 'translateX(-100%)'};
    }
`;

export const Logo = styled(DefaultImage)`
    width: 70%;
    justify-content: start;
    align-items: start;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
        width: 50%;
    }
`;

export const BurgerIcon = styled.div<any>`
    display: block;
    position: fixed;
    top: 20px;
    ${({ isRTL }) => (isRTL ? 'right: 20px;' : 'left: 20px;')}
    font-size: 2rem;
    cursor: pointer;
    color: #fff;

    z-index: 1005;
    @media (max-width: 1024px) {
        display: block;
    }
`;
