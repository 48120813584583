import { Academy } from 'libs/types';

export const ACADEMY_INITIAL_VALUES: Academy = {
    id: '',
    contactNumber: '',
    isMultiBranch: false,
    name: '',
    registrationNumber: '',
    athletes: [],
    coaches: [],
    teams: [],
    sportProfiles: [],
};
