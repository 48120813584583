import React, { useEffect, useCallback } from 'react';
import * as Theme from './Theme';
import { TabButton } from '../tabButton';
import { useDispatch, useSelector } from 'react-redux';
import { authSlice, selectAuth } from 'store/authSlice';
import { router } from 'routers';
import { resetAction, setModalContent } from 'store/controlsSlice';
import { resetLocalesAction } from 'store/localesSlice';
import { resetAction as resetActionAcademy } from 'store/academySlice';
import useFilteredTabs from 'hooks/filterTabs/useFilteredTabs';

interface AsideProps {
    toggleMenu: () => void; // Accept toggleMenu as a prop
    menuOpen: boolean; // Pass menuOpen from the parent
}

export const Aside: React.FC<AsideProps> = ({ toggleMenu, menuOpen }) => {
    const dispatch = useDispatch();
    const { entities, academy, modalContent, isRTL } = useSelector((state: any) => ({
        entities: selectAuth(state).entities,
        academy: state.academy.academy,
        modalContent: state.controls.modalContent,
        isRTL: state.locales.isRTL,
    }));

    const filteredTabs = useFilteredTabs(entities?.role, academy);

    const handleLogout = useCallback(() => {
        dispatch(authSlice.actions.clearAuthData());
        dispatch(resetLocalesAction());
        dispatch(resetAction());
        dispatch(resetActionAcademy());
        window.history.replaceState({}, '', 'home');
        router.navigate('signin', {}, { reload: true });
    }, [dispatch]);

    useEffect(() => {
        if (modalContent.type === 'none' && modalContent.redirect?.condition) {
            const { id, path } = modalContent.redirect;
            router.navigate(path, id ? { id } : {});
            dispatch(setModalContent({ modalContent: { redirect: undefined } }));
        }
    }, [modalContent.type, modalContent.redirect, dispatch]);

    return (
        <Theme.Container isRTL={isRTL}>
            <Theme.BurgerIcon onClick={toggleMenu} isRTL={isRTL}>
                {menuOpen ? '✖' : '☰'}
            </Theme.BurgerIcon>

            <Theme.Body menuOpen={menuOpen} isRTL={isRTL}>
                <Theme.Logo src="/assets/icons/aside-logo.png" alt="logo" />
                {filteredTabs.slice(0, -1).map((item, index) => (
                    <TabButton
                        item={item}
                        setMenuOpen={toggleMenu} // Optionally close menu on click
                        key={`ListOption key: ${index}`}
                    />
                ))}
                <TabButton
                    item={{
                        name: 'logout',
                        iconPath: '/assets/icons/logout-icon.png',
                    }}
                    last={true}
                    onClick={handleLogout}
                />
            </Theme.Body>
        </Theme.Container>
    );
};
