import { selectControls } from 'store';
import * as Theme from './Theme';
import { useSelector } from 'react-redux';
import { ReactNode } from 'react';
import {
    Dashboard,
    AthleteList,
    TeamsList,
    CoachList,
    AcademyProfile,
    Contacts,
    Feedbacks,
    ApprovalUsers,
    SchedulePage,
    PublicAthleteLinks,
    BranchList,
} from 'pages';

export const Home = () => {
    const { activeTab } = useSelector(selectControls);

    const ComponentMap: { [key: string]: ReactNode } = {
        dashboard: <Dashboard />,
        schedule: <SchedulePage />,
        athleteList: <AthleteList />,
        teamList: <TeamsList />,
        coachList: <CoachList />,
        academyProfile: <AcademyProfile />,
        adminProfile: <></>,
        contacts: <Contacts />,
        feedbacks: <Feedbacks />,
        'approval-users': <ApprovalUsers />,
        'public-athlete-links': <PublicAthleteLinks />,
        branch: <BranchList />,
    };

    return <Theme.Body>{ComponentMap[activeTab]}</Theme.Body>;
};
