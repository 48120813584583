import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
`;
export const Body = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
