import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls } from 'store/controlsSlice';
import { TabButton } from '../tabButton';
import { ASIDE_TABS, getAvatarPlaceholder } from 'libs/constants';
import { StoreState } from 'libs/types';
import { userRoleMapping } from 'libs/constants';
import { Gender } from 'libs/enums';
import { router } from 'routers';
import { switchActiveTab } from 'store/controlsSlice';
import { selectAuth } from 'store/authSlice';
import { useLocales } from 'hooks/locales';
import * as Theme from './Theme';
import NotificationMenu from './notifcationMenu/NotificationMenu';

export const Header: React.FC = () => {
    const { activeTab } = useSelector(selectControls);
    const { entities } = useSelector(selectAuth);
    const user = useSelector((state: StoreState) => state.auth?.entities);
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();
    const handleAvatarClick = () => {
        if (entities?.userId) {
            dispatch(switchActiveTab({ activeTab: 'adminProfile' }));
            router.navigate('profile', { id: entities?.userId }, { replace: true });
        }
    };

    return (
        <Theme.Body>
            <Theme.AvatarWrapper onClick={handleAvatarClick}>
                <Theme.Avatar
                    alt="avatar"
                    src={user?.avatar || getAvatarPlaceholder(Gender.MALE)}
                />
                <div>{user?.username || ''}</div>
                <Theme.AvatarRole
                    className="uppercase"
                    value={trans(user?.role || '')}
                    variant="h5"
                />
            </Theme.AvatarWrapper>
            <NotificationMenu />
        </Theme.Body>
    );
};
