import styled, { css } from 'styled-components';
import {
    InputController as DefaultInputController,
    Button as DefaultButton,
    Text as DefaultText,
    Link as DefaultLink,
    Image as DefaultImage,
} from 'components';
import { mq } from 'theme/mq';

export const Body = styled.form.attrs({
    className: 'flex items-center justify-center w-full',
})`
    height: auto;
    flex-direction: column;

    @media (${mq.laptop}) {
        height: 100%;
        flex-direction: row;
    }
`;

export const FormSection = styled.section.attrs({
    className: 'flex flex-col justify-center items-center',
})`
    width: 100vw;
    background-color: #fff;
    color: #000;
    padding: 50px 70px;
    overflow: hidden;
    position: relative;
    height: 120vh;

    @media (${mq.laptop}) {
        height: 100%;
        padding: 0 70px;
        width: 40vw;
    }
`;

export const FormSlider = styled.div.attrs({
    className: 'flex justify-center items-center',
})<{ stage: number; $isRTL: boolean }>`
    transition: 0.7s ease-in-out;
    position: absolute;
    top: 0;
    ${(props) =>
        props.$isRTL
            ? css`
                  right: ${(props.stage - 1) * -100}vw;
              `
            : css`
                  left: ${(props.stage - 1) * -100}vw;
              `}

    width: 200vw;
    height: 100%;

    @media (${mq.laptop}) {
        ${(props) =>
            props.$isRTL
                ? css`
                      right: ${(props.stage - 1) * -40}vw;
                  `
                : css`
                      left: ${(props.stage - 1) * -40}vw;
                  `}

        width: 80vw;
    }
`;

export const StageWrapper = styled.div.attrs({
    className: 'flex flex-col justify-center items-center relative',
})`
    padding: 0 70px;
    height: 100%;
    width: 100vw;

    @media (${mq.laptop}) {
        width: 40vw;
    }
`;

export const BackButton = styled(DefaultImage)``;

export const BackButtonBody = styled.button<{ $isRTL: boolean }>`
    transition: opacity 0.3s ease;
    position: absolute;
    height: auto;
    padding: 10px;
    top: 70px;
    ${(props) => (props.$isRTL ? 'right: 80px;' : 'left: 80px;')}
    cursor: pointer;
    z-index: 1231;
    &:hover {
        opacity: 0.5;
    }
`;
export const FormTitle = styled(DefaultText)`
    font-weight: bold;
`;

export const SubtitleWrapper = styled.div.attrs({
    className: 'flex justify-center items-center relative',
})`
    padding: 20px 0;
    height: 60px;
    width: 100%;
`;

export const FormSubtitle = styled(DefaultText)`
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
    z-index: 2;
`;

export const SubtitleLine = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: #a9a9a9;
    opacity: 0.5;
`;

export const InputController = styled(DefaultInputController).attrs({ className: 'flex flex-col' })`
    width: 100%;
    gap: 5px;
`;

export const Button = styled(DefaultButton)`
    min-width: 100%;
`;

export const SentenceWrapper = styled.div.attrs({
    className: 'flex gap-1 items-center',
})`
    justify-content: end;
    font-size: 13px;
    width: 100%;

    @media (${mq.tablet}) {
        justify-content: center;
    }
`;

export const SentenceText = styled(DefaultText)`
    width: fit-content;
`;

export const SentenceLink = styled(DefaultLink)``;

export const ErrorText = styled.div`
    color: red;
`;

export const Errors = styled.div.attrs({ className: 'flex justify-center items-center' })`
    height: 40px;
    color: red;
`;
