import React, { forwardRef } from 'react';
import { useController, Control } from 'react-hook-form';
import * as InputTheme from './Theme';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocales } from 'hooks/locales';
import moment from 'moment-timezone';
import { Controls } from 'components';

interface InputControllerProps {
    name: string;
    control: Control<any>;
    label?: string;
    defaultValue?: string;
    withPortal?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

export const InputDateController: React.FC<
    InputControllerProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
    label,
    control,
    defaultValue,
    name,
    withPortal = true,
    minDate = new Date('01/01/1940'),
    maxDate = new Date(),
    ...rest
}) => {
    const { timezonDate, trans, formatDate } = useLocales();
    const { field, fieldState } = useController<any>({
        control,
        name,
        defaultValue: defaultValue ? timezonDate(defaultValue) : undefined,
    });

    const handleDateChange = (date: Date) => {
        if (date) {
            const correctedDate = timezonDate(date);
            field.onChange(correctedDate);
        }
    };

    const ExampleCustomInput = forwardRef((props: any, ref: any) => (
        <InputTheme.CustomButton type="button" {...{ ...props }} ref={ref}>
            {field.value ? formatDate(props?.value) : trans('form.selectDate')}
        </InputTheme.CustomButton>
    ));

    return (
        <InputTheme.Body {...rest}>
            <DatePicker
                id={`date-picker-${name}`}
                selected={field.value ? timezonDate(field.value) : null}
                onChange={handleDateChange}
                closeOnScroll={true}
                withPortal={withPortal}
                fixedHeight
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={trans('form.selectDate')}
                customInput={<ExampleCustomInput />}
            />
            <Controls.Hint>{fieldState?.error?.message}</Controls.Hint>
        </InputTheme.Body>
    );
};
