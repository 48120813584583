import { UserRole } from 'libs/enums';

export enum RouteNames {
    HOME = 'home',
    SIGNIN = 'signin',
    FORGET_PASSWORD = 'forget_password',
    REST_PASSWORD = 'reset_password',
    SIGNUP = 'signup',
    ATHLETE = 'athlete',
    ADD_ATHLETE = 'addAthlete',
    TEAM = 'team',
    ADD_TEAM = 'addTeam',
    ADD_COACH = 'addCoach',
    COACH = 'coach',
    SESSION = 'session',
    PROFILE = 'profile',
    FEEDBACKS = 'feedbacks',
    APPROVAL_USERS = 'approval-users',
    SCHEDULE = 'schedule',
    SESSION_UPDATE = 'session_update',
    ATHLETE_PUBLIC = 'athlete_public',
    NOTIFICATION = 'notification',
    CONFIRM_SESSION = 'confirm_session',
    ADMIN_MANAGER = 'admin_manager',
    BRANCH = 'branch',
    ADD_BRANCH = 'addBranch',
}

export interface RouteConfig {
    Page: React.ComponentType;
    Layout: React.ComponentType<any>;
    isAuthPage: boolean;
    allowedRoles: UserRole[];
}
