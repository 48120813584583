import { useDispatch, useSelector } from 'react-redux';
import * as Theme from './Theme';
import { selectControls } from 'store';
import { useLocales } from 'hooks/locales';
import { router } from 'routers';
import { ActiveTab } from 'libs/enums';
import { setBreadCrumps, switchActiveTab } from 'store/controlsSlice';
import { replace } from 'lodash';

export const TabButton = ({
    item: { name, iconPath },
    last,
    setMenuOpen,
    onClick,
    ...rest
}: {
    item: { name: any; iconPath: string };
    onClick?: () => void;
    setMenuOpen?: any;
    last?: boolean;
}) => {
    const dispatch = useDispatch<any>();
    const { activeTab } = useSelector(selectControls);

    const handleReplaceAllHistoryEntries = () => {
        window.history.replaceState({}, '', 'home');
        router.navigate('home', { replace: true });
    };
    const handleSwitchTab = (activeTab: ActiveTab) => {
        dispatch(switchActiveTab({ activeTab }));
        dispatch(setBreadCrumps({ breadCrumps: [activeTab] }));
        handleReplaceAllHistoryEntries();
        setMenuOpen && setMenuOpen(false);
    };

    const { trans } = useLocales();

    return (
        <Theme.Tab
            $isActive={activeTab === name}
            $last={last}
            onClick={() => (onClick ? onClick() : handleSwitchTab(name))}
            $isButton={true}
            {...rest}
        >
            <Theme.TabIcon src={iconPath} alt={name} />
            <Theme.TabText variant="p" value={trans(`home.${name}`)} />
        </Theme.Tab>
    );
};
