import styled from 'styled-components';
import { Image as DefaultImage, Text as DefaultText } from 'components';

export const Body = styled.header.attrs({ className: 'fixed flex justify-end' })`
    background-color: #000;
    color: #fff;
    padding: 0 60px;
    width: 80vw;
    height: 88px;
    z-index: 1000;
    display: flex;
    width: 100%;
    gap: 30px;
`;

export const NotificationIcon = styled(DefaultImage).attrs({
    className: 'my-auto ms-auto me-[50px]',
})`
    width: 20px;
`;

export const AvatarWrapper = styled.div.attrs({
    className: 'grid  grid-cols-[auto_auto] my-auto gap-x-[20px] cursor-pointer',
})``;

export const Avatar = styled(DefaultImage)`
    margin: auto;
    grid-row: span 2;
    width: 40px;
    height: 40px;
    border-radius: 100px;
`;

export const AvatarRole = styled(DefaultText)`
    margin-right: auto;
    opacity: 0.5;
`;
