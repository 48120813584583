import React from 'react';
import { useController, Control } from 'react-hook-form';
import { Controls } from 'components';
import * as InputTheme from './Theme';
import Select, { components, GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { useLocales } from 'hooks/locales';
import makeAnimated from 'react-select/animated';

type item = {
    value: string;
    label: string;
};
interface SelectControllerProps {
    label?: string;
    name: string;
    control: Control<any>;
    options: Array<item>;
    isMulti?: true | undefined;
    isCreatable?: boolean;
    DropdownIndicatorIcon?: string;
    transSuffix?: string;
    style?: React.CSSProperties;
}

export const MultiSelectController: React.FC<SelectControllerProps> = ({
    label,
    control,
    options,
    name,
    isMulti,
    isCreatable = false,
    DropdownIndicatorIcon,
    transSuffix,
    style,
    ...rest
}) => {
    const { field, fieldState } = useController<any>({
        control,
        name,
    });
    const { trans } = useLocales();

    const DropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props}>
            <InputTheme.Icon src={DropdownIndicatorIcon} alt="icon" />
        </components.DropdownIndicator>
    );

    const selectComponents: Partial<SelectComponents<any, true, GroupBase<any>>> = {};

    if (DropdownIndicatorIcon) selectComponents['DropdownIndicator'] = DropdownIndicator;

    const animatedComponents = makeAnimated();

    const selectOptions = !options
        ? []
        : options.map((item) => ({
              value: item.value,
              label: transSuffix ? trans(`${transSuffix}${item.value}`) : item.label,
          }));

    const selectedValue = isMulti
        ? selectOptions.filter((option) => {
              // Check if field.value contains objects or strings
              const isObjectArray = typeof field?.value?.[0] === 'object';

              if (isObjectArray) {
                  // If field.value contains objects, map over the objects to extract the value property
                  return field?.value
                      ?.map((val: { value: any }) => val.value)
                      .includes(option?.value);
              } else {
                  // If field.value contains strings, directly use includes
                  return field?.value?.includes(option?.value);
              }
          }) // If multiple values
        : selectOptions.find((option) => option.value === field.value); // Single value

    const styles: any = {
        multiValue: (base: any) => ({
            ...base,
            border: '1px solid #c0d330',
            backgroundColor: '#fbffd2',
            borderRadius: '15px',
            padding: '0px 6px',
        }),
        multiValueRemove: (base: any) => ({
            ...base,
            borderRadius: '3px',
        }),
        menu: (base: any) => ({
            ...base,
            zIndex: 100, // Ensure the dropdown is above other elements
            maxHeight: '300px', // Set the max height for the dropdown
            overflowY: 'auto', // Enable scrolling if content exceeds max height
        }),
        menuList: (base: any) => ({
            ...base,
            maxHeight: '150px', // Set the max height for the options list
            padding: 0, // Optional: to remove padding
        }),
    };
    return (
        <InputTheme.Body style={style}>
            <Controls.Label>{label}</Controls.Label>
            {isCreatable ? (
                <CreatableSelect
                    closeMenuOnSelect={isMulti ? false : true}
                    isMulti={isMulti}
                    options={transSuffix ? selectOptions : options || []}
                    components={{ ...selectComponents, ...animatedComponents }}
                    styles={styles}
                    {...field}
                    {...rest}
                />
            ) : (
                <Select
                    closeMenuOnSelect={isMulti ? false : true}
                    isMulti={isMulti}
                    options={transSuffix ? selectOptions : options || []}
                    components={{ ...selectComponents, ...animatedComponents }}
                    menuPlacement="top"
                    styles={styles}
                    {...field}
                    value={selectedValue} // Use the mapped object here
                    // Set first option as default if no value
                    {...rest}
                    placeholder={trans('form.select')}
                />
            )}
            <Controls.Hint>{fieldState?.error?.message}</Controls.Hint>
        </InputTheme.Body>
    );
};
