import React from 'react';

export const Forbidden: React.FC = () => {
    return (
        <div>
            <h1>403 - Forbidden</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    );
};
