import { useForm, SubmitHandler } from 'react-hook-form';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { includes } from 'lodash';
import { SUCCESS_STATUS } from 'libs/constants';
import { AuthBanner } from 'components';
import { setModalContent } from 'store/controlsSlice';
import { useRouter } from 'react-router5';
import { ResetPasswordFormData, useResetPasswordSchema } from './resetPasswordSchema';
import { useResetPassword } from 'services/hooks';

export const ResetPassword = () => {
    const router = useRouter();
    const {
        params: { token },
    } = router.getState();

    const resetPasswordSchema = useResetPasswordSchema();
    const dispatch = useDispatch<any>();
    const [errorText, setErrorText] = useState('');
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = useForm<ResetPasswordFormData>({
        mode: 'all',
        resolver: yupResolver(resetPasswordSchema),
    });
    const { trans } = useLocales();

    const { mutate: resetPassword, isPending: isLoading } = useResetPassword({
        onSuccess: (data) => {
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'info',
                        title: 'Info',
                        subtitle: trans('resetPassword.successMessage'),
                    },
                }),
            );
            router.navigate('signin', {}, { replace: true });
        },
        onError: (error: any) => {
            setErrorText(trans('resetPassword.failureMessage'));
        },
    });

    const handleResetPassword: SubmitHandler<ResetPasswordFormData> = (data) => {
        resetPassword({ token, password: data.password });
    };

    return (
        <Theme.Body onSubmit={handleSubmit(handleResetPassword)}>
            <AuthBanner />
            <Theme.FormSection>
                <Theme.FormTitle variant="h2" value={trans('resetPassword.formTitle')} />
                <Theme.FormSubtitle variant="h5" value={trans('resetPassword.formSubtitle')} />
                <Theme.InputController
                    type="password"
                    autoComplete="new-password"
                    {...{
                        control,
                        name: 'password',
                        label: trans('resetPassword.password', { defaultValue: 'Password' }),
                        placeholder: trans('resetPassword.passwordPlaceholder', {
                            defaultValue: 'Password',
                        }),
                    }}
                />
                <Theme.InputController
                    type="password"
                    autoComplete="new-password"
                    {...{
                        control,
                        name: 'confirmPassword',
                        label: trans('resetPassword.confirmPassword', {
                            defaultValue: 'Confirm Password',
                        }),
                        placeholder: trans('resetPassword.confirmPasswordPlaceholder', {
                            defaultValue: 'Confirm Password',
                        }),
                    }}
                />
                <Theme.Errors>{errorText}</Theme.Errors>
                <Theme.Button disabled={!isValid || isLoading} isLoading={isLoading} type="submit">
                    {trans('resetPassword.submit')}
                </Theme.Button>
            </Theme.FormSection>
        </Theme.Body>
    );
};
