'use strict';

export const bmiData = [
    {
        Sex: '1',
        Agemos: '24.5',
        L: '-1.982373595',
        M: '16.54777487',
        S: '0.080127429',
        P95: '19.27889813',
    },
    {
        Sex: '1',
        Agemos: '25.5',
        L: '-1.924100169',
        M: '16.49442763',
        S: '0.079233994',
        P95: '19.16465965',
    },
    {
        Sex: '1',
        Agemos: '26.5',
        L: '-1.86549793',
        M: '16.44259552',
        S: '0.078389356',
        P95: '19.05567423',
    },
    {
        Sex: '1',
        Agemos: '27.5',
        L: '-1.807261899',
        M: '16.3922434',
        S: '0.077593501',
        P95: '18.9518675',
    },
    {
        Sex: '1',
        Agemos: '28.5',
        L: '-1.750118905',
        M: '16.34333654',
        S: '0.076846462',
        P95: '18.85316529',
    },
    {
        Sex: '1',
        Agemos: '29.5',
        L: '-1.69481584',
        M: '16.29584097',
        S: '0.076148308',
        P95: '18.75949359',
    },
    {
        Sex: '1',
        Agemos: '30.5',
        L: '-1.642106779',
        M: '16.24972371',
        S: '0.075499126',
        P95: '18.67077841',
    },
    {
        Sex: '1',
        Agemos: '31.5',
        L: '-1.592744414',
        M: '16.20495268',
        S: '0.074898994',
        P95: '18.58694589',
    },
    {
        Sex: '1',
        Agemos: '32.5',
        L: '-1.547442391',
        M: '16.16149871',
        S: '0.074347997',
        P95: '18.50792131',
    },
    {
        Sex: '1',
        Agemos: '33.5',
        L: '-1.506902601',
        M: '16.11933258',
        S: '0.073846139',
        P95: '18.43363072',
    },
    {
        Sex: '1',
        Agemos: '34.5',
        L: '-1.471770047',
        M: '16.07842758',
        S: '0.07339337',
        P95: '18.3639996',
    },
    {
        Sex: '1',
        Agemos: '35.5',
        L: '-1.442628957',
        M: '16.03875896',
        S: '0.072989551',
        P95: '18.29895323',
    },
    {
        Sex: '1',
        Agemos: '36.5',
        L: '-1.419991255',
        M: '16.00030401',
        S: '0.072634432',
        P95: '18.23841666',
    },
    {
        Sex: '1',
        Agemos: '37.5',
        L: '-1.404277619',
        M: '15.96304277',
        S: '0.072327649',
        P95: '18.1823144',
    },
    {
        Sex: '1',
        Agemos: '38.5',
        L: '-1.39586317',
        M: '15.92695418',
        S: '0.07206864',
        P95: '18.13057244',
    },
    {
        Sex: '1',
        Agemos: '39.5',
        L: '-1.394935252',
        M: '15.89202582',
        S: '0.071856805',
        P95: '18.08311311',
    },
    {
        Sex: '1',
        Agemos: '40.5',
        L: '-1.401671596',
        M: '15.85824093',
        S: '0.071691278',
        P95: '18.03986198',
    },
    {
        Sex: '1',
        Agemos: '41.5',
        L: '-1.416100312',
        M: '15.82558822',
        S: '0.071571093',
        P95: '18.00074262',
    },
    {
        Sex: '1',
        Agemos: '42.5',
        L: '-1.438164899',
        M: '15.79405728',
        S: '0.071495113',
        P95: '17.96567903',
    },
    {
        Sex: '1',
        Agemos: '43.5',
        L: '-1.467669032',
        M: '15.76364255',
        S: '0.071462106',
        P95: '17.9345935',
    },
    {
        Sex: '1',
        Agemos: '44.5',
        L: '-1.504376347',
        M: '15.73433668',
        S: '0.071470646',
        P95: '17.90741007',
    },
    {
        Sex: '1',
        Agemos: '45.5',
        L: '-1.547942838',
        M: '15.70613566',
        S: '0.071519218',
        P95: '17.88405183',
    },
    {
        Sex: '1',
        Agemos: '46.5',
        L: '-1.597896397',
        M: '15.67904062',
        S: '0.071606277',
        P95: '17.86443983',
    },
    {
        Sex: '1',
        Agemos: '47.5',
        L: '-1.653732283',
        M: '15.65305192',
        S: '0.071730167',
        P95: '17.84849622',
    },
    {
        Sex: '1',
        Agemos: '48.5',
        L: '-1.714869347',
        M: '15.62817269',
        S: '0.071889214',
        P95: '17.8361423',
    },
    {
        Sex: '1',
        Agemos: '49.5',
        L: '-1.780673181',
        M: '15.604408',
        S: '0.072081737',
        P95: '17.82729891',
    },
    {
        Sex: '1',
        Agemos: '50.5',
        L: '-1.850468473',
        M: '15.58176458',
        S: '0.072306081',
        P95: '17.8218865',
    },
    {
        Sex: '1',
        Agemos: '51.5',
        L: '-1.923551865',
        M: '15.56025067',
        S: '0.072560637',
        P95: '17.81982523',
    },
    {
        Sex: '1',
        Agemos: '52.5',
        L: '-1.999220429',
        M: '15.5398746',
        S: '0.07284384',
        P95: '17.82103559',
    },
    {
        Sex: '1',
        Agemos: '53.5',
        L: '-2.076707178',
        M: '15.52064993',
        S: '0.073154324',
        P95: '17.82543578',
    },
    {
        Sex: '1',
        Agemos: '54.5',
        L: '-2.155348017',
        M: '15.50258427',
        S: '0.073490667',
        P95: '17.83294744',
    },
    {
        Sex: '1',
        Agemos: '55.5',
        L: '-2.234438552',
        M: '15.48568973',
        S: '0.073851672',
        P95: '17.84349028',
    },
    {
        Sex: '1',
        Agemos: '56.5',
        L: '-2.313321723',
        M: '15.46997718',
        S: '0.074236235',
        P95: '17.85698505',
    },
    {
        Sex: '1',
        Agemos: '57.5',
        L: '-2.391381273',
        M: '15.45545692',
        S: '0.074643374',
        P95: '17.87335318',
    },
    {
        Sex: '1',
        Agemos: '58.5',
        L: '-2.468032491',
        M: '15.44213961',
        S: '0.075072264',
        P95: '17.89251642',
    },
    {
        Sex: '1',
        Agemos: '59.5',
        L: '-2.542781541',
        M: '15.43003207',
        S: '0.075522104',
        P95: '17.91439894',
    },
    {
        Sex: '1',
        Agemos: '60.5',
        L: '-2.61516595',
        M: '15.41914163',
        S: '0.07599225',
        P95: '17.93892524',
    },
    {
        Sex: '1',
        Agemos: '61.5',
        L: '-2.684789516',
        M: '15.40947356',
        S: '0.076482128',
        P95: '17.96602144',
    },
    {
        Sex: '1',
        Agemos: '62.5',
        L: '-2.751316949',
        M: '15.40103139',
        S: '0.076991232',
        P95: '17.99561523',
    },
    {
        Sex: '1',
        Agemos: '63.5',
        L: '-2.81445945',
        M: '15.39381785',
        S: '0.077519149',
        P95: '18.02763543',
    },
    {
        Sex: '1',
        Agemos: '64.5',
        L: '-2.87402476',
        M: '15.38783094',
        S: '0.07806539',
        P95: '18.06201396',
    },
    {
        Sex: '1',
        Agemos: '65.5',
        L: '-2.92984048',
        M: '15.38306945',
        S: '0.078629592',
        P95: '18.09868317',
    },
    {
        Sex: '1',
        Agemos: '66.5',
        L: '-2.981796828',
        M: '15.37952958',
        S: '0.079211369',
        P95: '18.13757754',
    },
    {
        Sex: '1',
        Agemos: '67.5',
        L: '-3.029831343',
        M: '15.37720582',
        S: '0.079810334',
        P95: '18.17863327',
    },
    {
        Sex: '1',
        Agemos: '68.5',
        L: '-3.073924224',
        M: '15.37609107',
        S: '0.080426086',
        P95: '18.22178824',
    },
    {
        Sex: '1',
        Agemos: '69.5',
        L: '-3.114093476',
        M: '15.37617677',
        S: '0.081058206',
        P95: '18.26698191',
    },
    {
        Sex: '1',
        Agemos: '70.5',
        L: '-3.15039004',
        M: '15.37745304',
        S: '0.081706249',
        P95: '18.31415529',
    },
    {
        Sex: '1',
        Agemos: '71.5',
        L: '-3.182893018',
        M: '15.37990886',
        S: '0.082369741',
        P95: '18.36325085',
    },
    {
        Sex: '1',
        Agemos: '72.5',
        L: '-3.21170511',
        M: '15.38353217',
        S: '0.083048178',
        P95: '18.41421242',
    },
    {
        Sex: '1',
        Agemos: '73.5',
        L: '-3.23694834',
        M: '15.38831005',
        S: '0.083741021',
        P95: '18.46698511',
    },
    {
        Sex: '1',
        Agemos: '74.5',
        L: '-3.25876011',
        M: '15.39422883',
        S: '0.0844477',
        P95: '18.52151524',
    },
    {
        Sex: '1',
        Agemos: '75.5',
        L: '-3.277281546',
        M: '15.40127496',
        S: '0.085167651',
        P95: '18.57774996',
    },
    {
        Sex: '1',
        Agemos: '76.5',
        L: '-3.292683774',
        M: '15.40943252',
        S: '0.085900184',
        P95: '18.63563816',
    },
    {
        Sex: '1',
        Agemos: '77.5',
        L: '-3.305124073',
        M: '15.41868691',
        S: '0.086644667',
        P95: '18.69512904',
    },
    {
        Sex: '1',
        Agemos: '78.5',
        L: '-3.314768951',
        M: '15.42902273',
        S: '0.087400421',
        P95: '18.75617288',
    },
    {
        Sex: '1',
        Agemos: '79.5',
        L: '-3.321785992',
        M: '15.44042439',
        S: '0.088166744',
        P95: '18.81872078',
    },
    {
        Sex: '1',
        Agemos: '80.5',
        L: '-3.326345795',
        M: '15.45287581',
        S: '0.088942897',
        P95: '18.88272474',
    },
    {
        Sex: '1',
        Agemos: '81.5',
        L: '-3.328602731',
        M: '15.46636218',
        S: '0.089728202',
        P95: '18.948137',
    },
    {
        Sex: '1',
        Agemos: '82.5',
        L: '-3.328725277',
        M: '15.48086704',
        S: '0.090521875',
        P95: '19.01491107',
    },
    {
        Sex: '1',
        Agemos: '83.5',
        L: '-3.32687018',
        M: '15.49637465',
        S: '0.091323162',
        P95: '19.08300081',
    },
    {
        Sex: '1',
        Agemos: '84.5',
        L: '-3.323188896',
        M: '15.51286936',
        S: '0.092131305',
        P95: '19.15236073',
    },
    {
        Sex: '1',
        Agemos: '85.5',
        L: '-3.317827016',
        M: '15.53033563',
        S: '0.092945544',
        P95: '19.22294589',
    },
    {
        Sex: '1',
        Agemos: '86.5',
        L: '-3.310923871',
        M: '15.54875807',
        S: '0.093765118',
        P95: '19.29471191',
    },
    {
        Sex: '1',
        Agemos: '87.5',
        L: '-3.302612272',
        M: '15.56812143',
        S: '0.09458927',
        P95: '19.36761493',
    },
    {
        Sex: '1',
        Agemos: '88.5',
        L: '-3.293018361',
        M: '15.58841065',
        S: '0.095417247',
        P95: '19.44161165',
    },
    {
        Sex: '1',
        Agemos: '89.5',
        L: '-3.282260813',
        M: '15.60961101',
        S: '0.096248301',
        P95: '19.51665894',
    },
    {
        Sex: '1',
        Agemos: '90.5',
        L: '-3.270454609',
        M: '15.63170735',
        S: '0.097081694',
        P95: '19.59271539',
    },
    {
        Sex: '1',
        Agemos: '91.5',
        L: '-3.257703616',
        M: '15.65468563',
        S: '0.097916698',
        P95: '19.6697383',
    },
    {
        Sex: '1',
        Agemos: '92.5',
        L: '-3.244108214',
        M: '15.67853139',
        S: '0.098752593',
        P95: '19.74768666',
    },
    {
        Sex: '1',
        Agemos: '93.5',
        L: '-3.229761713',
        M: '15.70323052',
        S: '0.099588675',
        P95: '19.82651963',
    },
    {
        Sex: '1',
        Agemos: '94.5',
        L: '-3.214751287',
        M: '15.72876911',
        S: '0.100424251',
        P95: '19.90619689',
    },
    {
        Sex: '1',
        Agemos: '95.5',
        L: '-3.199158184',
        M: '15.75513347',
        S: '0.101258643',
        P95: '19.98667859',
    },
    {
        Sex: '1',
        Agemos: '96.5',
        L: '-3.18305795',
        M: '15.78231007',
        S: '0.102091189',
        P95: '20.06792538',
    },
    {
        Sex: '1',
        Agemos: '97.5',
        L: '-3.166520664',
        M: '15.8102856',
        S: '0.102921245',
        P95: '20.1498984',
    },
    {
        Sex: '1',
        Agemos: '98.5',
        L: '-3.1496103',
        M: '15.83904708',
        S: '0.103748189',
        P95: '20.23255928',
    },
    {
        Sex: '1',
        Agemos: '99.5',
        L: '-3.132389637',
        M: '15.86858123',
        S: '0.104571386',
        P95: '20.31587026',
    },
    {
        Sex: '1',
        Agemos: '100.5',
        L: '-3.114911153',
        M: '15.89887562',
        S: '0.105390269',
        P95: '20.39979379',
    },
    {
        Sex: '1',
        Agemos: '101.5',
        L: '-3.097226399',
        M: '15.92991765',
        S: '0.106204258',
        P95: '20.48429323',
    },
    {
        Sex: '1',
        Agemos: '102.5',
        L: '-3.079383079',
        M: '15.96169481',
        S: '0.107012788',
        P95: '20.56933216',
    },
    {
        Sex: '1',
        Agemos: '103.5',
        L: '-3.061423765',
        M: '15.99419489',
        S: '0.107815327',
        P95: '20.6548748',
    },
    {
        Sex: '1',
        Agemos: '104.5',
        L: '-3.043386071',
        M: '16.02740607',
        S: '0.108611374',
        P95: '20.74088584',
    },
    {
        Sex: '1',
        Agemos: '105.5',
        L: '-3.025310003',
        M: '16.0613159',
        S: '0.109400388',
        P95: '20.82733059',
    },
    {
        Sex: '1',
        Agemos: '106.5',
        L: '-3.007225737',
        M: '16.09591292',
        S: '0.110181915',
        P95: '20.9141748',
    },
    {
        Sex: '1',
        Agemos: '107.5',
        L: '-2.989164598',
        M: '16.13118532',
        S: '0.110955478',
        P95: '21.00138483',
    },
    {
        Sex: '1',
        Agemos: '108.5',
        L: '-2.971148225',
        M: '16.16712234',
        S: '0.111720691',
        P95: '21.08892746',
    },
    {
        Sex: '1',
        Agemos: '109.5',
        L: '-2.953208047',
        M: '16.20371168',
        S: '0.112477059',
        P95: '21.17677023',
    },
    {
        Sex: '1',
        Agemos: '110.5',
        L: '-2.935363951',
        M: '16.24094239',
        S: '0.1132242',
        P95: '21.26488107',
    },
    {
        Sex: '1',
        Agemos: '111.5',
        L: '-2.917635157',
        M: '16.27880346',
        S: '0.113961734',
        P95: '21.3532285',
    },
    {
        Sex: '1',
        Agemos: '112.5',
        L: '-2.900039803',
        M: '16.31728385',
        S: '0.114689291',
        P95: '21.44178165',
    },
    {
        Sex: '1',
        Agemos: '113.5',
        L: '-2.882593796',
        M: '16.35637267',
        S: '0.115406523',
        P95: '21.53051015',
    },
    {
        Sex: '1',
        Agemos: '114.5',
        L: '-2.865311266',
        M: '16.39605916',
        S: '0.116113097',
        P95: '21.61938426',
    },
    {
        Sex: '1',
        Agemos: '115.5',
        L: '-2.848204697',
        M: '16.43633265',
        S: '0.116808702',
        P95: '21.70837477',
    },
    {
        Sex: '1',
        Agemos: '116.5',
        L: '-2.831285052',
        M: '16.47718256',
        S: '0.117493042',
        P95: '21.79745308',
    },
    {
        Sex: '1',
        Agemos: '117.5',
        L: '-2.81456189',
        M: '16.51859843',
        S: '0.11816584',
        P95: '21.88659115',
    },
    {
        Sex: '1',
        Agemos: '118.5',
        L: '-2.79804347',
        M: '16.56056987',
        S: '0.118826835',
        P95: '21.97576153',
    },
    {
        Sex: '1',
        Agemos: '119.5',
        L: '-2.781736856',
        M: '16.60308661',
        S: '0.119475785',
        P95: '22.06493737',
    },
    {
        Sex: '1',
        Agemos: '120.5',
        L: '-2.765648008',
        M: '16.64613844',
        S: '0.120112464',
        P95: '22.15409238',
    },
    {
        Sex: '1',
        Agemos: '121.5',
        L: '-2.749782197',
        M: '16.68971518',
        S: '0.120736656',
        P95: '22.24320091',
    },
    {
        Sex: '1',
        Agemos: '122.5',
        L: '-2.734142443',
        M: '16.73380695',
        S: '0.121348181',
        P95: '22.33223786',
    },
    {
        Sex: '1',
        Agemos: '123.5',
        L: '-2.718732873',
        M: '16.77840363',
        S: '0.121946849',
        P95: '22.42117875',
    },
    {
        Sex: '1',
        Agemos: '124.5',
        L: '-2.703555506',
        M: '16.82349538',
        S: '0.122532501',
        P95: '22.5099997',
    },
    {
        Sex: '1',
        Agemos: '125.5',
        L: '-2.688611957',
        M: '16.86907238',
        S: '0.123104991',
        P95: '22.59867744',
    },
    {
        Sex: '1',
        Agemos: '126.5',
        L: '-2.673903164',
        M: '16.91512487',
        S: '0.123664186',
        P95: '22.68718928',
    },
    {
        Sex: '1',
        Agemos: '127.5',
        L: '-2.659429443',
        M: '16.96164317',
        S: '0.124209969',
        P95: '22.77551316',
    },
    {
        Sex: '1',
        Agemos: '128.5',
        L: '-2.645190534',
        M: '17.00861766',
        S: '0.124742239',
        P95: '22.86362762',
    },
    {
        Sex: '1',
        Agemos: '129.5',
        L: '-2.631185649',
        M: '17.05603879',
        S: '0.125260905',
        P95: '22.95151182',
    },
    {
        Sex: '1',
        Agemos: '130.5',
        L: '-2.617413511',
        M: '17.10389705',
        S: '0.125765895',
        P95: '23.03914551',
    },
    {
        Sex: '1',
        Agemos: '131.5',
        L: '-2.603872392',
        M: '17.15218302',
        S: '0.126257147',
        P95: '23.12650908',
    },
    {
        Sex: '1',
        Agemos: '132.5',
        L: '-2.590560148',
        M: '17.20088732',
        S: '0.126734613',
        P95: '23.21358351',
    },
    {
        Sex: '1',
        Agemos: '133.5',
        L: '-2.577474253',
        M: '17.25000062',
        S: '0.12719826',
        P95: '23.30035043',
    },
    {
        Sex: '1',
        Agemos: '134.5',
        L: '-2.564611831',
        M: '17.29951367',
        S: '0.127648067',
        P95: '23.38679204',
    },
    {
        Sex: '1',
        Agemos: '135.5',
        L: '-2.551969684',
        M: '17.34941726',
        S: '0.128084023',
        P95: '23.4728912',
    },
    {
        Sex: '1',
        Agemos: '136.5',
        L: '-2.539539972',
        M: '17.39970308',
        S: '0.128506192',
        P95: '23.55863129',
    },
    {
        Sex: '1',
        Agemos: '137.5',
        L: '-2.527325681',
        M: '17.45036072',
        S: '0.128914497',
        P95: '23.64399652',
    },
    {
        Sex: '1',
        Agemos: '138.5',
        L: '-2.515320235',
        M: '17.50138161',
        S: '0.129309001',
        P95: '23.72897155',
    },
    {
        Sex: '1',
        Agemos: '139.5',
        L: '-2.503519447',
        M: '17.55275674',
        S: '0.129689741',
        P95: '23.81354171',
    },
    {
        Sex: '1',
        Agemos: '140.5',
        L: '-2.491918934',
        M: '17.60447714',
        S: '0.130056765',
        P95: '23.89769296',
    },
    {
        Sex: '1',
        Agemos: '141.5',
        L: '-2.480514136',
        M: '17.6565339',
        S: '0.130410133',
        P95: '23.98141187',
    },
    {
        Sex: '1',
        Agemos: '142.5',
        L: '-2.469300331',
        M: '17.70891811',
        S: '0.130749913',
        P95: '24.06468566',
    },
    {
        Sex: '1',
        Agemos: '143.5',
        L: '-2.458272656',
        M: '17.76162094',
        S: '0.131076187',
        P95: '24.14750216',
    },
    {
        Sex: '1',
        Agemos: '144.5',
        L: '-2.447426113',
        M: '17.81463359',
        S: '0.131389042',
        P95: '24.22984982',
    },
    {
        Sex: '1',
        Agemos: '145.5',
        L: '-2.436755595',
        M: '17.86794729',
        S: '0.131688579',
        P95: '24.31171774',
    },
    {
        Sex: '1',
        Agemos: '146.5',
        L: '-2.426255887',
        M: '17.92155332',
        S: '0.131974905',
        P95: '24.39309562',
    },
    {
        Sex: '1',
        Agemos: '147.5',
        L: '-2.415921689',
        M: '17.97544299',
        S: '0.132248138',
        P95: '24.47397382',
    },
    {
        Sex: '1',
        Agemos: '148.5',
        L: '-2.405747619',
        M: '18.02960765',
        S: '0.132508403',
        P95: '24.55434332',
    },
    {
        Sex: '1',
        Agemos: '149.5',
        L: '-2.395728233',
        M: '18.08403868',
        S: '0.132755834',
        P95: '24.63419572',
    },
    {
        Sex: '1',
        Agemos: '150.5',
        L: '-2.385858029',
        M: '18.1387275',
        S: '0.132990575',
        P95: '24.71352325',
    },
    {
        Sex: '1',
        Agemos: '151.5',
        L: '-2.376131459',
        M: '18.19366555',
        S: '0.133212776',
        P95: '24.79231881',
    },
    {
        Sex: '1',
        Agemos: '152.5',
        L: '-2.366542942',
        M: '18.24884431',
        S: '0.133422595',
        P95: '24.87057587',
    },
    {
        Sex: '1',
        Agemos: '153.5',
        L: '-2.357086871',
        M: '18.3042553',
        S: '0.133620197',
        P95: '24.9482886',
    },
    {
        Sex: '1',
        Agemos: '154.5',
        L: '-2.347757625',
        M: '18.35989003',
        S: '0.133805756',
        P95: '25.02545175',
    },
    {
        Sex: '1',
        Agemos: '155.5',
        L: '-2.338549576',
        M: '18.41574009',
        S: '0.133979452',
        P95: '25.10206075',
    },
    {
        Sex: '1',
        Agemos: '156.5',
        L: '-2.3294571',
        M: '18.47179706',
        S: '0.13414147',
        P95: '25.17811163',
    },
    {
        Sex: '1',
        Agemos: '157.5',
        L: '-2.320474586',
        M: '18.52805255',
        S: '0.134292005',
        P95: '25.25360108',
    },
    {
        Sex: '1',
        Agemos: '158.5',
        L: '-2.311596446',
        M: '18.5844982',
        S: '0.134431256',
        P95: '25.32852641',
    },
    {
        Sex: '1',
        Agemos: '159.5',
        L: '-2.302817124',
        M: '18.64112567',
        S: '0.134559427',
        P95: '25.4028856',
    },
    {
        Sex: '1',
        Agemos: '160.5',
        L: '-2.294131107',
        M: '18.69792663',
        S: '0.134676731',
        P95: '25.47667722',
    },
    {
        Sex: '1',
        Agemos: '161.5',
        L: '-2.285532933',
        M: '18.75489278',
        S: '0.134783385',
        P95: '25.54990054',
    },
    {
        Sex: '1',
        Agemos: '162.5',
        L: '-2.277017201',
        M: '18.81201584',
        S: '0.134879611',
        P95: '25.62255542',
    },
    {
        Sex: '1',
        Agemos: '163.5',
        L: '-2.268578584',
        M: '18.86928753',
        S: '0.134965637',
        P95: '25.6946424',
    },
    {
        Sex: '1',
        Agemos: '164.5',
        L: '-2.260211837',
        M: '18.92669959',
        S: '0.135041695',
        P95: '25.76616263',
    },
    {
        Sex: '1',
        Agemos: '165.5',
        L: '-2.251911809',
        M: '18.98424378',
        S: '0.135108024',
        P95: '25.83711794',
    },
    {
        Sex: '1',
        Agemos: '166.5',
        L: '-2.243673453',
        M: '19.04191185',
        S: '0.135164867',
        P95: '25.90751079',
    },
    {
        Sex: '1',
        Agemos: '167.5',
        L: '-2.235491842',
        M: '19.09969557',
        S: '0.135212469',
        P95: '25.97734428',
    },
    {
        Sex: '1',
        Agemos: '168.5',
        L: '-2.227362173',
        M: '19.15758672',
        S: '0.135251083',
        P95: '26.04662217',
    },
    {
        Sex: '1',
        Agemos: '169.5',
        L: '-2.21927979',
        M: '19.21557707',
        S: '0.135280963',
        P95: '26.11534887',
    },
    {
        Sex: '1',
        Agemos: '170.5',
        L: '-2.211240187',
        M: '19.27365839',
        S: '0.135302371',
        P95: '26.18352944',
    },
    {
        Sex: '1',
        Agemos: '171.5',
        L: '-2.203239029',
        M: '19.33182247',
        S: '0.135315568',
        P95: '26.25116959',
    },
    {
        Sex: '1',
        Agemos: '172.5',
        L: '-2.195272161',
        M: '19.39006106',
        S: '0.135320824',
        P95: '26.31827569',
    },
    {
        Sex: '1',
        Agemos: '173.5',
        L: '-2.187335625',
        M: '19.44836594',
        S: '0.135318407',
        P95: '26.38485476',
    },
    {
        Sex: '1',
        Agemos: '174.5',
        L: '-2.179425674',
        M: '19.50672885',
        S: '0.135308594',
        P95: '26.45091448',
    },
    {
        Sex: '1',
        Agemos: '175.5',
        L: '-2.171538789',
        M: '19.56514153',
        S: '0.135291662',
        P95: '26.51646321',
    },
    {
        Sex: '1',
        Agemos: '176.5',
        L: '-2.163671689',
        M: '19.62359571',
        S: '0.135267891',
        P95: '26.58150994',
    },
    {
        Sex: '1',
        Agemos: '177.5',
        L: '-2.155821357',
        M: '19.6820831',
        S: '0.135237567',
        P95: '26.64606434',
    },
    {
        Sex: '1',
        Agemos: '178.5',
        L: '-2.147985046',
        M: '19.74059538',
        S: '0.135200976',
        P95: '26.71013675',
    },
    {
        Sex: '1',
        Agemos: '179.5',
        L: '-2.140160305',
        M: '19.7991242',
        S: '0.135158409',
        P95: '26.77373817',
    },
    {
        Sex: '1',
        Agemos: '180.5',
        L: '-2.132344989',
        M: '19.85766121',
        S: '0.135110159',
        P95: '26.83688026',
    },
    {
        Sex: '1',
        Agemos: '181.5',
        L: '-2.124537282',
        M: '19.916198',
        S: '0.135056522',
        P95: '26.89957537',
    },
    {
        Sex: '1',
        Agemos: '182.5',
        L: '-2.116735712',
        M: '19.97472615',
        S: '0.134997797',
        P95: '26.96183652',
    },
    {
        Sex: '1',
        Agemos: '183.5',
        L: '-2.108939167',
        M: '20.03323719',
        S: '0.134934285',
        P95: '27.02367739',
    },
    {
        Sex: '1',
        Agemos: '184.5',
        L: '-2.10114692',
        M: '20.09172262',
        S: '0.134866291',
        P95: '27.08511234',
    },
    {
        Sex: '1',
        Agemos: '185.5',
        L: '-2.093358637',
        M: '20.15017387',
        S: '0.134794121',
        P95: '27.14615643',
    },
    {
        Sex: '1',
        Agemos: '186.5',
        L: '-2.085574403',
        M: '20.20858236',
        S: '0.134718085',
        P95: '27.20682538',
    },
    {
        Sex: '1',
        Agemos: '187.5',
        L: '-2.077794735',
        M: '20.26693944',
        S: '0.134638494',
        P95: '27.2671356',
    },
    {
        Sex: '1',
        Agemos: '188.5',
        L: '-2.070020599',
        M: '20.32523642',
        S: '0.134555663',
        P95: '27.32710417',
    },
    {
        Sex: '1',
        Agemos: '189.5',
        L: '-2.062253431',
        M: '20.38346455',
        S: '0.13446991',
        P95: '27.38674888',
    },
    {
        Sex: '1',
        Agemos: '190.5',
        L: '-2.054495145',
        M: '20.44161501',
        S: '0.134381553',
        P95: '27.4460882',
    },
    {
        Sex: '1',
        Agemos: '191.5',
        L: '-2.046748156',
        M: '20.49967894',
        S: '0.134290916',
        P95: '27.50514126',
    },
    {
        Sex: '1',
        Agemos: '192.5',
        L: '-2.039015385',
        M: '20.5576474',
        S: '0.134198323',
        P95: '27.56392793',
    },
    {
        Sex: '1',
        Agemos: '193.5',
        L: '-2.031300282',
        M: '20.6155114',
        S: '0.134104101',
        P95: '27.62246873',
    },
    {
        Sex: '1',
        Agemos: '194.5',
        L: '-2.023606828',
        M: '20.67326189',
        S: '0.134008581',
        P95: '27.68078489',
    },
    {
        Sex: '1',
        Agemos: '195.5',
        L: '-2.015942013',
        M: '20.73088905',
        S: '0.133912066',
        P95: '27.73889854',
    },
    {
        Sex: '1',
        Agemos: '196.5',
        L: '-2.008305745',
        M: '20.7883851',
        S: '0.133814954',
        P95: '27.79683185',
    },
    {
        Sex: '1',
        Agemos: '197.5',
        L: '-2.000706389',
        M: '20.84574003',
        S: '0.133717552',
        P95: '27.85460837',
    },
    {
        Sex: '1',
        Agemos: '198.5',
        L: '-1.993150137',
        M: '20.90294449',
        S: '0.1336202',
        P95: '27.91225209',
    },
    {
        Sex: '1',
        Agemos: '199.5',
        L: '-1.985643741',
        M: '20.95998909',
        S: '0.133523244',
        P95: '27.96978771',
    },
    {
        Sex: '1',
        Agemos: '200.5',
        L: '-1.97819451',
        M: '21.01686433',
        S: '0.133427032',
        P95: '28.02724061',
    },
    {
        Sex: '1',
        Agemos: '201.5',
        L: '-1.970810308',
        M: '21.07356067',
        S: '0.133331914',
        P95: '28.08463689',
    },
    {
        Sex: '1',
        Agemos: '202.5',
        L: '-1.96349954',
        M: '21.1300685',
        S: '0.133238245',
        P95: '28.1420033',
    },
    {
        Sex: '1',
        Agemos: '203.5',
        L: '-1.956271141',
        M: '21.18637813',
        S: '0.133146383',
        P95: '28.19936732',
    },
    {
        Sex: '1',
        Agemos: '204.5',
        L: '-1.949134561',
        M: '21.24247982',
        S: '0.13305669',
        P95: '28.25675709',
    },
    {
        Sex: '1',
        Agemos: '205.5',
        L: '-1.942099744',
        M: '21.29836376',
        S: '0.132969531',
        P95: '28.31420146',
    },
    {
        Sex: '1',
        Agemos: '206.5',
        L: '-1.935177101',
        M: '21.35402009',
        S: '0.132885274',
        P95: '28.37172995',
    },
    {
        Sex: '1',
        Agemos: '207.5',
        L: '-1.92837748',
        M: '21.40943891',
        S: '0.132804292',
        P95: '28.42937274',
    },
    {
        Sex: '1',
        Agemos: '208.5',
        L: '-1.921712136',
        M: '21.46461026',
        S: '0.132726962',
        P95: '28.48716074',
    },
    {
        Sex: '1',
        Agemos: '209.5',
        L: '-1.915192685',
        M: '21.51952414',
        S: '0.132653664',
        P95: '28.54512548',
    },
    {
        Sex: '1',
        Agemos: '210.5',
        L: '-1.908831065',
        M: '21.57417053',
        S: '0.132584784',
        P95: '28.60329919',
    },
    {
        Sex: '1',
        Agemos: '211.5',
        L: '-1.902639482',
        M: '21.62853937',
        S: '0.132520711',
        P95: '28.66171476',
    },
    {
        Sex: '1',
        Agemos: '212.5',
        L: '-1.896630358',
        M: '21.68262062',
        S: '0.132461838',
        P95: '28.72040572',
    },
    {
        Sex: '1',
        Agemos: '213.5',
        L: '-1.890816268',
        M: '21.73640419',
        S: '0.132408563',
        P95: '28.77940629',
    },
    {
        Sex: '1',
        Agemos: '214.5',
        L: '-1.885209876',
        M: '21.78988003',
        S: '0.132361289',
        P95: '28.8387513',
    },
    {
        Sex: '1',
        Agemos: '215.5',
        L: '-1.879823505',
        M: '21.84303819',
        S: '0.132320427',
        P95: '28.89847621',
    },
    {
        Sex: '1',
        Agemos: '216.5',
        L: '-1.874670324',
        M: '21.8958685',
        S: '0.132286382',
        P95: '28.9586172',
    },
    {
        Sex: '1',
        Agemos: '217.5',
        L: '-1.869760299',
        M: '21.94836168',
        S: '0.1322596',
        P95: '29.0192108',
    },
    {
        Sex: '1',
        Agemos: '218.5',
        L: '-1.865113245',
        M: '22.00050569',
        S: '0.132240418',
        P95: '29.08029503',
    },
    {
        Sex: '1',
        Agemos: '219.5',
        L: '-1.860734944',
        M: '22.05229242',
        S: '0.13222933',
        P95: '29.14190734',
    },
    {
        Sex: '1',
        Agemos: '220.5',
        L: '-1.85663384',
        M: '22.10371305',
        S: '0.132226801',
        P95: '29.20408599',
    },
    {
        Sex: '1',
        Agemos: '221.5',
        L: '-1.852827186',
        M: '22.15475603',
        S: '0.132233201',
        P95: '29.26687079',
    },
    {
        Sex: '1',
        Agemos: '222.5',
        L: '-1.849323204',
        M: '22.20541249',
        S: '0.132248993',
        P95: '29.33030127',
    },
    {
        Sex: '1',
        Agemos: '223.5',
        L: '-1.846131607',
        M: '22.255673',
        S: '0.132274625',
        P95: '29.39441777',
    },
    {
        Sex: '1',
        Agemos: '224.5',
        L: '-1.843261294',
        M: '22.30552831',
        S: '0.132310549',
        P95: '29.45926122',
    },
    {
        Sex: '1',
        Agemos: '225.5',
        L: '-1.840720248',
        M: '22.3549693',
        S: '0.132357221',
        P95: '29.52487306',
    },
    {
        Sex: '1',
        Agemos: '226.5',
        L: '-1.83851544',
        M: '22.40398706',
        S: '0.132415103',
        P95: '29.59129532',
    },
    {
        Sex: '1',
        Agemos: '227.5',
        L: '-1.83665586',
        M: '22.45257182',
        S: '0.132484631',
        P95: '29.65857091',
    },
    {
        Sex: '1',
        Agemos: '228.5',
        L: '-1.835138046',
        M: '22.50071778',
        S: '0.132566359',
        P95: '29.72674204',
    },
    {
        Sex: '1',
        Agemos: '229.5',
        L: '-1.833972004',
        M: '22.54841437',
        S: '0.132660699',
        P95: '29.79585303',
    },
    {
        Sex: '1',
        Agemos: '230.5',
        L: '-1.833157751',
        M: '22.59565422',
        S: '0.132768153',
        P95: '29.86594782',
    },
    {
        Sex: '1',
        Agemos: '231.5',
        L: '-1.83269562',
        M: '22.64242956',
        S: '0.132889211',
        P95: '29.93707103',
    },
    {
        Sex: '1',
        Agemos: '232.5',
        L: '-1.832584342',
        M: '22.68873292',
        S: '0.133024368',
        P95: '30.00926778',
    },
    {
        Sex: '1',
        Agemos: '233.5',
        L: '-1.832820974',
        M: '22.73455713',
        S: '0.133174129',
        P95: '30.08258369',
    },
    {
        Sex: '1',
        Agemos: '234.5',
        L: '-1.833400825',
        M: '22.7798953',
        S: '0.133338999',
        P95: '30.15706484',
    },
    {
        Sex: '1',
        Agemos: '235.5',
        L: '-1.834317405',
        M: '22.82474087',
        S: '0.133519496',
        P95: '30.23275779',
    },
    {
        Sex: '1',
        Agemos: '236.5',
        L: '-1.83555752',
        M: '22.86908912',
        S: '0.133716192',
        P95: '30.30970901',
    },
    {
        Sex: '1',
        Agemos: '237.5',
        L: '-1.837119466',
        M: '22.91293151',
        S: '0.133929525',
        P95: '30.387967',
    },
    {
        Sex: '1',
        Agemos: '238.5',
        L: '-1.838987063',
        M: '22.95626373',
        S: '0.134160073',
        P95: '30.46757924',
    },
    {
        Sex: '1',
        Agemos: '239.5',
        L: '-1.841146139',
        M: '22.99908062',
        S: '0.134408381',
        P95: '30.54859414',
    },
    {
        Sex: '2',
        Agemos: '24.5',
        L: '-1.024496827',
        M: '16.38804056',
        S: '0.085025838',
        P95: '19.05823845',
    },
    {
        Sex: '2',
        Agemos: '25.5',
        L: '-1.102698353',
        M: '16.3189719',
        S: '0.084214052',
        P95: '18.9659499',
    },
    {
        Sex: '2',
        Agemos: '26.5',
        L: '-1.18396635',
        M: '16.25207985',
        S: '0.083455124',
        P95: '18.87853388',
    },
    {
        Sex: '2',
        Agemos: '27.5',
        L: '-1.268071036',
        M: '16.18734669',
        S: '0.082748284',
        P95: '18.79590999',
    },
    {
        Sex: '2',
        Agemos: '28.5',
        L: '-1.354751525',
        M: '16.12475448',
        S: '0.082092737',
        P95: '18.71799839',
    },
    {
        Sex: '2',
        Agemos: '29.5',
        L: '-1.443689692',
        M: '16.06428762',
        S: '0.081487717',
        P95: '18.64471845',
    },
    {
        Sex: '2',
        Agemos: '30.5',
        L: '-1.53454192',
        M: '16.00593001',
        S: '0.080932448',
        P95: '18.5759903',
    },
    {
        Sex: '2',
        Agemos: '31.5',
        L: '-1.626928093',
        M: '15.94966631',
        S: '0.080426175',
        P95: '18.51173402',
    },
    {
        Sex: '2',
        Agemos: '32.5',
        L: '-1.720434829',
        M: '15.89548197',
        S: '0.079968176',
        P95: '18.45186978',
    },
    {
        Sex: '2',
        Agemos: '33.5',
        L: '-1.814635262',
        M: '15.84336179',
        S: '0.079557735',
        P95: '18.39631848',
    },
    {
        Sex: '2',
        Agemos: '34.5',
        L: '-1.909076262',
        M: '15.79329146',
        S: '0.079194187',
        P95: '18.34500099',
    },
    {
        Sex: '2',
        Agemos: '35.5',
        L: '-2.003296102',
        M: '15.7452564',
        S: '0.078876895',
        P95: '18.29783876',
    },
    {
        Sex: '2',
        Agemos: '36.5',
        L: '-2.096828937',
        M: '15.69924188',
        S: '0.078605255',
        P95: '18.25475381',
    },
    {
        Sex: '2',
        Agemos: '37.5',
        L: '-2.189211877',
        M: '15.65523282',
        S: '0.078378696',
        P95: '18.21566879',
    },
    {
        Sex: '2',
        Agemos: '38.5',
        L: '-2.279991982',
        M: '15.61321371',
        S: '0.078196674',
        P95: '18.18050711',
    },
    {
        Sex: '2',
        Agemos: '39.5',
        L: '-2.368732949',
        M: '15.57316843',
        S: '0.078058667',
        P95: '18.14919304',
    },
    {
        Sex: '2',
        Agemos: '40.5',
        L: '-2.455021314',
        M: '15.53508019',
        S: '0.077964169',
        P95: '18.12165176',
    },
    {
        Sex: '2',
        Agemos: '41.5',
        L: '-2.538471972',
        M: '15.49893145',
        S: '0.077912684',
        P95: '18.09780945',
    },
    {
        Sex: '2',
        Agemos: '42.5',
        L: '-2.618732901',
        M: '15.46470384',
        S: '0.077903716',
        P95: '18.07759336',
    },
    {
        Sex: '2',
        Agemos: '43.5',
        L: '-2.695488973',
        M: '15.43237817',
        S: '0.077936763',
        P95: '18.06093184',
    },
    {
        Sex: '2',
        Agemos: '44.5',
        L: '-2.768464816',
        M: '15.40193436',
        S: '0.078011309',
        P95: '18.04775438',
    },
    {
        Sex: '2',
        Agemos: '45.5',
        L: '-2.837426693',
        M: '15.37335154',
        S: '0.078126817',
        P95: '18.03799161',
    },
    {
        Sex: '2',
        Agemos: '46.5',
        L: '-2.902178205',
        M: '15.34660842',
        S: '0.078282739',
        P95: '18.03157513',
    },
    {
        Sex: '2',
        Agemos: '47.5',
        L: '-2.962580386',
        M: '15.32168181',
        S: '0.078478449',
        P95: '18.02843822',
    },
    {
        Sex: '2',
        Agemos: '48.5',
        L: '-3.018521987',
        M: '15.29854897',
        S: '0.078713325',
        P95: '18.02851473',
    },
    {
        Sex: '2',
        Agemos: '49.5',
        L: '-3.069936555',
        M: '15.27718618',
        S: '0.078986694',
        P95: '18.03173977',
    },
    {
        Sex: '2',
        Agemos: '50.5',
        L: '-3.116795864',
        M: '15.2575692',
        S: '0.079297841',
        P95: '18.03804946',
    },
    {
        Sex: '2',
        Agemos: '51.5',
        L: '-3.159107331',
        M: '15.23967338',
        S: '0.079646006',
        P95: '18.04738091',
    },
    {
        Sex: '2',
        Agemos: '52.5',
        L: '-3.196911083',
        M: '15.22347371',
        S: '0.080030389',
        P95: '18.05967216',
    },
    {
        Sex: '2',
        Agemos: '53.5',
        L: '-3.230276759',
        M: '15.20894491',
        S: '0.080450145',
        P95: '18.0748621',
    },
    {
        Sex: '2',
        Agemos: '54.5',
        L: '-3.259300182',
        M: '15.19606152',
        S: '0.080904391',
        P95: '18.09289045',
    },
    {
        Sex: '2',
        Agemos: '55.5',
        L: '-3.284099963',
        M: '15.18479799',
        S: '0.081392203',
        P95: '18.11369768',
    },
    {
        Sex: '2',
        Agemos: '56.5',
        L: '-3.30481415',
        M: '15.17512871',
        S: '0.081912623',
        P95: '18.13722499',
    },
    {
        Sex: '2',
        Agemos: '57.5',
        L: '-3.321596954',
        M: '15.16702811',
        S: '0.082464661',
        P95: '18.16341422',
    },
    {
        Sex: '2',
        Agemos: '58.5',
        L: '-3.334615646',
        M: '15.16047068',
        S: '0.083047295',
        P95: '18.19220786',
    },
    {
        Sex: '2',
        Agemos: '59.5',
        L: '-3.344047622',
        M: '15.15543107',
        S: '0.083659478',
        P95: '18.22354893',
    },
    {
        Sex: '2',
        Agemos: '60.5',
        L: '-3.35007771',
        M: '15.15188405',
        S: '0.084300139',
        P95: '18.25738105',
    },
    {
        Sex: '2',
        Agemos: '61.5',
        L: '-3.352893805',
        M: '15.14980479',
        S: '0.0849682',
        P95: '18.29364826',
    },
    {
        Sex: '2',
        Agemos: '62.5',
        L: '-3.352691376',
        M: '15.14916825',
        S: '0.085662539',
        P95: '18.33229525',
    },
    {
        Sex: '2',
        Agemos: '63.5',
        L: '-3.34966438',
        M: '15.14994984',
        S: '0.086382035',
        P95: '18.37326713',
    },
    {
        Sex: '2',
        Agemos: '64.5',
        L: '-3.343998803',
        M: '15.15212585',
        S: '0.087125591',
        P95: '18.41650918',
    },
    {
        Sex: '2',
        Agemos: '65.5',
        L: '-3.335889574',
        M: '15.15567186',
        S: '0.087892047',
        P95: '18.46196754',
    },
    {
        Sex: '2',
        Agemos: '66.5',
        L: '-3.325522491',
        M: '15.16056419',
        S: '0.088680264',
        P95: '18.50958858',
    },
    {
        Sex: '2',
        Agemos: '67.5',
        L: '-3.31307846',
        M: '15.16677947',
        S: '0.089489106',
        P95: '18.55931907',
    },
    {
        Sex: '2',
        Agemos: '68.5',
        L: '-3.298732648',
        M: '15.17429464',
        S: '0.090317434',
        P95: '18.6111062',
    },
    {
        Sex: '2',
        Agemos: '69.5',
        L: '-3.282653831',
        M: '15.18308694',
        S: '0.091164117',
        P95: '18.6648976',
    },
    {
        Sex: '2',
        Agemos: '70.5',
        L: '-3.265003896',
        M: '15.1931339',
        S: '0.092028028',
        P95: '18.72064127',
    },
    {
        Sex: '2',
        Agemos: '71.5',
        L: '-3.245937506',
        M: '15.20441335',
        S: '0.092908048',
        P95: '18.77828567',
    },
    {
        Sex: '2',
        Agemos: '72.5',
        L: '-3.225606516',
        M: '15.21690296',
        S: '0.093803033',
        P95: '18.83777954',
    },
    {
        Sex: '2',
        Agemos: '73.5',
        L: '-3.204146115',
        M: '15.2305815',
        S: '0.094711916',
        P95: '18.89907229',
    },
    {
        Sex: '2',
        Agemos: '74.5',
        L: '-3.181690237',
        M: '15.24542745',
        S: '0.095633595',
        P95: '18.96211354',
    },
    {
        Sex: '2',
        Agemos: '75.5',
        L: '-3.158363475',
        M: '15.26141966',
        S: '0.096566992',
        P95: '19.02685341',
    },
    {
        Sex: '2',
        Agemos: '76.5',
        L: '-3.134282833',
        M: '15.27853728',
        S: '0.097511046',
        P95: '19.09324243',
    },
    {
        Sex: '2',
        Agemos: '77.5',
        L: '-3.109557879',
        M: '15.29675967',
        S: '0.09846471',
        P95: '19.16123158',
    },
    {
        Sex: '2',
        Agemos: '78.5',
        L: '-3.084290931',
        M: '15.31606644',
        S: '0.099426955',
        P95: '19.23077228',
    },
    {
        Sex: '2',
        Agemos: '79.5',
        L: '-3.058577292',
        M: '15.33643745',
        S: '0.100396769',
        P95: '19.30181639',
    },
    {
        Sex: '2',
        Agemos: '80.5',
        L: '-3.032505499',
        M: '15.35785274',
        S: '0.101373159',
        P95: '19.37431624',
    },
    {
        Sex: '2',
        Agemos: '81.5',
        L: '-3.0061576',
        M: '15.38029261',
        S: '0.10235515',
        P95: '19.44822461',
    },
    {
        Sex: '2',
        Agemos: '82.5',
        L: '-2.979609448',
        M: '15.40373754',
        S: '0.103341788',
        P95: '19.52349477',
    },
    {
        Sex: '2',
        Agemos: '83.5',
        L: '-2.952930993',
        M: '15.42816819',
        S: '0.104332139',
        P95: '19.60008044',
    },
    {
        Sex: '2',
        Agemos: '84.5',
        L: '-2.926186592',
        M: '15.45356545',
        S: '0.105325289',
        P95: '19.67793583',
    },
    {
        Sex: '2',
        Agemos: '85.5',
        L: '-2.899435307',
        M: '15.47991037',
        S: '0.106320346',
        P95: '19.75701564',
    },
    {
        Sex: '2',
        Agemos: '86.5',
        L: '-2.872731211',
        M: '15.50718419',
        S: '0.10731644',
        P95: '19.83727506',
    },
    {
        Sex: '2',
        Agemos: '87.5',
        L: '-2.846123683',
        M: '15.53536829',
        S: '0.108312721',
        P95: '19.91866977',
    },
    {
        Sex: '2',
        Agemos: '88.5',
        L: '-2.819657704',
        M: '15.56444426',
        S: '0.109308364',
        P95: '20.00115598',
    },
    {
        Sex: '2',
        Agemos: '89.5',
        L: '-2.793374145',
        M: '15.5943938',
        S: '0.110302563',
        P95: '20.08469037',
    },
    {
        Sex: '2',
        Agemos: '90.5',
        L: '-2.767310047',
        M: '15.6251988',
        S: '0.111294537',
        P95: '20.16923016',
    },
    {
        Sex: '2',
        Agemos: '91.5',
        L: '-2.741498897',
        M: '15.65684126',
        S: '0.112283526',
        P95: '20.25473307',
    },
    {
        Sex: '2',
        Agemos: '92.5',
        L: '-2.715970894',
        M: '15.68930333',
        S: '0.113268793',
        P95: '20.34115737',
    },
    {
        Sex: '2',
        Agemos: '93.5',
        L: '-2.690753197',
        M: '15.7225673',
        S: '0.114249622',
        P95: '20.42846182',
    },
    {
        Sex: '2',
        Agemos: '94.5',
        L: '-2.665870146',
        M: '15.75661555',
        S: '0.115225321',
        P95: '20.51660573',
    },
    {
        Sex: '2',
        Agemos: '95.5',
        L: '-2.641343436',
        M: '15.79143062',
        S: '0.116195218',
        P95: '20.60554896',
    },
    {
        Sex: '2',
        Agemos: '96.5',
        L: '-2.617192204',
        M: '15.82699517',
        S: '0.117158667',
        P95: '20.69525186',
    },
    {
        Sex: '2',
        Agemos: '97.5',
        L: '-2.593430614',
        M: '15.86329241',
        S: '0.118115073',
        P95: '20.78567525',
    },
    {
        Sex: '2',
        Agemos: '98.5',
        L: '-2.570076037',
        M: '15.90030484',
        S: '0.119063807',
        P95: '20.87678068',
    },
    {
        Sex: '2',
        Agemos: '99.5',
        L: '-2.547141473',
        M: '15.93801545',
        S: '0.12000429',
        P95: '20.96853014',
    },
    {
        Sex: '2',
        Agemos: '100.5',
        L: '-2.524635245',
        M: '15.97640787',
        S: '0.120935994',
        P95: '21.06088607',
    },
    {
        Sex: '2',
        Agemos: '101.5',
        L: '-2.502569666',
        M: '16.01546483',
        S: '0.121858355',
        P95: '21.15381165',
    },
    {
        Sex: '2',
        Agemos: '102.5',
        L: '-2.48095189',
        M: '16.05516984',
        S: '0.12277087',
        P95: '21.24727049',
    },
    {
        Sex: '2',
        Agemos: '103.5',
        L: '-2.459785573',
        M: '16.09550688',
        S: '0.123673085',
        P95: '21.34122666',
    },
    {
        Sex: '2',
        Agemos: '104.5',
        L: '-2.439080117',
        M: '16.13645881',
        S: '0.124564484',
        P95: '21.43564505',
    },
    {
        Sex: '2',
        Agemos: '105.5',
        L: '-2.418838304',
        M: '16.17800955',
        S: '0.125444639',
        P95: '21.5304909',
    },
    {
        Sex: '2',
        Agemos: '106.5',
        L: '-2.399063683',
        M: '16.22014281',
        S: '0.126313121',
        P95: '21.62573005',
    },
    {
        Sex: '2',
        Agemos: '107.5',
        L: '-2.379756861',
        M: '16.26284277',
        S: '0.127169545',
        P95: '21.72132885',
    },
    {
        Sex: '2',
        Agemos: '108.5',
        L: '-2.360920527',
        M: '16.30609316',
        S: '0.128013515',
        P95: '21.8172543',
    },
    {
        Sex: '2',
        Agemos: '109.5',
        L: '-2.342557728',
        M: '16.34987759',
        S: '0.128844639',
        P95: '21.91347402',
    },
    {
        Sex: '2',
        Agemos: '110.5',
        L: '-2.324663326',
        M: '16.39418118',
        S: '0.129662637',
        P95: '22.00995586',
    },
    {
        Sex: '2',
        Agemos: '111.5',
        L: '-2.307240716',
        M: '16.43898741',
        S: '0.130467138',
        P95: '22.10666864',
    },
    {
        Sex: '2',
        Agemos: '112.5',
        L: '-2.290287663',
        M: '16.48428082',
        S: '0.131257852',
        P95: '22.20358151',
    },
    {
        Sex: '2',
        Agemos: '113.5',
        L: '-2.273803847',
        M: '16.53004554',
        S: '0.132034479',
        P95: '22.30066429',
    },
    {
        Sex: '2',
        Agemos: '114.5',
        L: '-2.257782149',
        M: '16.57626713',
        S: '0.132796819',
        P95: '22.39788709',
    },
    {
        Sex: '2',
        Agemos: '115.5',
        L: '-2.242227723',
        M: '16.62292864',
        S: '0.133544525',
        P95: '22.49522108',
    },
    {
        Sex: '2',
        Agemos: '116.5',
        L: '-2.227132805',
        M: '16.67001572',
        S: '0.134277436',
        P95: '22.59263755',
    },
    {
        Sex: '2',
        Agemos: '117.5',
        L: '-2.212495585',
        M: '16.71751288',
        S: '0.134995324',
        P95: '22.69010855',
    },
    {
        Sex: '2',
        Agemos: '118.5',
        L: '-2.19831275',
        M: '16.76540496',
        S: '0.135697996',
        P95: '22.78760665',
    },
    {
        Sex: '2',
        Agemos: '119.5',
        L: '-2.184580762',
        M: '16.81367689',
        S: '0.136385276',
        P95: '22.88510501',
    },
    {
        Sex: '2',
        Agemos: '120.5',
        L: '-2.171295888',
        M: '16.86231366',
        S: '0.137057004',
        P95: '22.98257733',
    },
    {
        Sex: '2',
        Agemos: '121.5',
        L: '-2.158454232',
        M: '16.91130036',
        S: '0.137713039',
        P95: '23.07999788',
    },
    {
        Sex: '2',
        Agemos: '122.5',
        L: '-2.146051754',
        M: '16.96062216',
        S: '0.138353254',
        P95: '23.17734149',
    },
    {
        Sex: '2',
        Agemos: '123.5',
        L: '-2.134084303',
        M: '17.0102643',
        S: '0.138977537',
        P95: '23.27458358',
    },
    {
        Sex: '2',
        Agemos: '124.5',
        L: '-2.122547629',
        M: '17.06021213',
        S: '0.139585795',
        P95: '23.3717001',
    },
    {
        Sex: '2',
        Agemos: '125.5',
        L: '-2.111437411',
        M: '17.11045106',
        S: '0.140177947',
        P95: '23.46866758',
    },
    {
        Sex: '2',
        Agemos: '126.5',
        L: '-2.100749266',
        M: '17.16096656',
        S: '0.140753927',
        P95: '23.56546313',
    },
    {
        Sex: '2',
        Agemos: '127.5',
        L: '-2.090478774',
        M: '17.21174424',
        S: '0.141313686',
        P95: '23.6620644',
    },
    {
        Sex: '2',
        Agemos: '128.5',
        L: '-2.080621484',
        M: '17.26276973',
        S: '0.141857186',
        P95: '23.7584496',
    },
    {
        Sex: '2',
        Agemos: '129.5',
        L: '-2.071172932',
        M: '17.31402878',
        S: '0.142384404',
        P95: '23.85459754',
    },
    {
        Sex: '2',
        Agemos: '130.5',
        L: '-2.062128649',
        M: '17.3655072',
        S: '0.142895332',
        P95: '23.95048756',
    },
    {
        Sex: '2',
        Agemos: '131.5',
        L: '-2.053484173',
        M: '17.4171909',
        S: '0.143389972',
        P95: '24.04609957',
    },
    {
        Sex: '2',
        Agemos: '132.5',
        L: '-2.045235058',
        M: '17.46906585',
        S: '0.143868341',
        P95: '24.14141407',
    },
    {
        Sex: '2',
        Agemos: '133.5',
        L: '-2.03737688',
        M: '17.52111811',
        S: '0.144330469',
        P95: '24.23641208',
    },
    {
        Sex: '2',
        Agemos: '134.5',
        L: '-2.029906684',
        M: '17.57333347',
        S: '0.144776372',
        P95: '24.33107526',
    },
    {
        Sex: '2',
        Agemos: '135.5',
        L: '-2.022817914',
        M: '17.62569869',
        S: '0.145206138',
        P95: '24.4253857',
    },
    {
        Sex: '2',
        Agemos: '136.5',
        L: '-2.016107084',
        M: '17.67819987',
        S: '0.145619819',
        P95: '24.51932616',
    },
    {
        Sex: '2',
        Agemos: '137.5',
        L: '-2.009769905',
        M: '17.7308234',
        S: '0.146017491',
        P95: '24.61287993',
    },
    {
        Sex: '2',
        Agemos: '138.5',
        L: '-2.003802134',
        M: '17.78355575',
        S: '0.146399239',
        P95: '24.70603086',
    },
    {
        Sex: '2',
        Agemos: '139.5',
        L: '-1.998199572',
        M: '17.83638347',
        S: '0.146765161',
        P95: '24.79876336',
    },
    {
        Sex: '2',
        Agemos: '140.5',
        L: '-1.992958064',
        M: '17.88929321',
        S: '0.147115364',
        P95: '24.89106241',
    },
    {
        Sex: '2',
        Agemos: '141.5',
        L: '-1.988073505',
        M: '17.94227168',
        S: '0.147449967',
        P95: '24.98291353',
    },
    {
        Sex: '2',
        Agemos: '142.5',
        L: '-1.983541835',
        M: '17.9953057',
        S: '0.147769097',
        P95: '25.07430281',
    },
    {
        Sex: '2',
        Agemos: '143.5',
        L: '-1.979359041',
        M: '18.04838216',
        S: '0.148072891',
        P95: '25.16521691',
    },
    {
        Sex: '2',
        Agemos: '144.5',
        L: '-1.975521156',
        M: '18.10148804',
        S: '0.148361495',
        P95: '25.25564304',
    },
    {
        Sex: '2',
        Agemos: '145.5',
        L: '-1.972024258',
        M: '18.15461039',
        S: '0.148635067',
        P95: '25.34556897',
    },
    {
        Sex: '2',
        Agemos: '146.5',
        L: '-1.968864465',
        M: '18.20773639',
        S: '0.148893769',
        P95: '25.43498301',
    },
    {
        Sex: '2',
        Agemos: '147.5',
        L: '-1.966037938',
        M: '18.26085325',
        S: '0.149137776',
        P95: '25.52387405',
    },
    {
        Sex: '2',
        Agemos: '148.5',
        L: '-1.963540872',
        M: '18.31394832',
        S: '0.14936727',
        P95: '25.61223153',
    },
    {
        Sex: '2',
        Agemos: '149.5',
        L: '-1.961369499',
        M: '18.36700902',
        S: '0.149582439',
        P95: '25.70004544',
    },
    {
        Sex: '2',
        Agemos: '150.5',
        L: '-1.959520079',
        M: '18.42002284',
        S: '0.149783482',
        P95: '25.78730634',
    },
    {
        Sex: '2',
        Agemos: '151.5',
        L: '-1.9579889',
        M: '18.47297739',
        S: '0.149970604',
        P95: '25.87400532',
    },
    {
        Sex: '2',
        Agemos: '152.5',
        L: '-1.956772271',
        M: '18.52586035',
        S: '0.15014402',
        P95: '25.96013405',
    },
    {
        Sex: '2',
        Agemos: '153.5',
        L: '-1.95586652',
        M: '18.57865951',
        S: '0.15030395',
        P95: '26.04568474',
    },
    {
        Sex: '2',
        Agemos: '154.5',
        L: '-1.955267984',
        M: '18.63136275',
        S: '0.150450621',
        P95: '26.13065016',
    },
    {
        Sex: '2',
        Agemos: '155.5',
        L: '-1.954973011',
        M: '18.68395801',
        S: '0.15058427',
        P95: '26.21502362',
    },
    {
        Sex: '2',
        Agemos: '156.5',
        L: '-1.954977947',
        M: '18.73643338',
        S: '0.150705138',
        P95: '26.298799',
    },
    {
        Sex: '2',
        Agemos: '157.5',
        L: '-1.955279136',
        M: '18.788777',
        S: '0.150813475',
        P95: '26.38197072',
    },
    {
        Sex: '2',
        Agemos: '158.5',
        L: '-1.955872909',
        M: '18.84097713',
        S: '0.150909535',
        P95: '26.46453376',
    },
    {
        Sex: '2',
        Agemos: '159.5',
        L: '-1.956755579',
        M: '18.89302212',
        S: '0.150993582',
        P95: '26.54648363',
    },
    {
        Sex: '2',
        Agemos: '160.5',
        L: '-1.957923436',
        M: '18.94490041',
        S: '0.151065883',
        P95: '26.62781643',
    },
    {
        Sex: '2',
        Agemos: '161.5',
        L: '-1.959372737',
        M: '18.99660055',
        S: '0.151126714',
        P95: '26.70852876',
    },
    {
        Sex: '2',
        Agemos: '162.5',
        L: '-1.9610997',
        M: '19.04811118',
        S: '0.151176355',
        P95: '26.78861781',
    },
    {
        Sex: '2',
        Agemos: '163.5',
        L: '-1.963100496',
        M: '19.09942105',
        S: '0.151215094',
        P95: '26.8680813',
    },
    {
        Sex: '2',
        Agemos: '164.5',
        L: '-1.96537124',
        M: '19.15051899',
        S: '0.151243223',
        P95: '26.94691749',
    },
    {
        Sex: '2',
        Agemos: '165.5',
        L: '-1.967907983',
        M: '19.20139397',
        S: '0.151261042',
        P95: '27.02512521',
    },
    {
        Sex: '2',
        Agemos: '166.5',
        L: '-1.970706706',
        M: '19.25203503',
        S: '0.151268855',
        P95: '27.10270382',
    },
    {
        Sex: '2',
        Agemos: '167.5',
        L: '-1.973763307',
        M: '19.30243131',
        S: '0.151266974',
        P95: '27.17965324',
    },
    {
        Sex: '2',
        Agemos: '168.5',
        L: '-1.977073595',
        M: '19.35257209',
        S: '0.151255713',
        P95: '27.25597392',
    },
    {
        Sex: '2',
        Agemos: '169.5',
        L: '-1.980633277',
        M: '19.40244671',
        S: '0.151235395',
        P95: '27.33166687',
    },
    {
        Sex: '2',
        Agemos: '170.5',
        L: '-1.984437954',
        M: '19.45204465',
        S: '0.151206347',
        P95: '27.40673363',
    },
    {
        Sex: '2',
        Agemos: '171.5',
        L: '-1.988483106',
        M: '19.50135548',
        S: '0.151168902',
        P95: '27.4811763',
    },
    {
        Sex: '2',
        Agemos: '172.5',
        L: '-1.992764085',
        M: '19.55036888',
        S: '0.151123398',
        P95: '27.55499753',
    },
    {
        Sex: '2',
        Agemos: '173.5',
        L: '-1.997276103',
        M: '19.59907464',
        S: '0.15107018',
        P95: '27.62820049',
    },
    {
        Sex: '2',
        Agemos: '174.5',
        L: '-2.002014224',
        M: '19.64746266',
        S: '0.151009595',
        P95: '27.70078892',
    },
    {
        Sex: '2',
        Agemos: '175.5',
        L: '-2.00697335',
        M: '19.69552294',
        S: '0.150942',
        P95: '27.77276709',
    },
    {
        Sex: '2',
        Agemos: '176.5',
        L: '-2.012148213',
        M: '19.7432456',
        S: '0.150867753',
        P95: '27.84413981',
    },
    {
        Sex: '2',
        Agemos: '177.5',
        L: '-2.017533363',
        M: '19.79062086',
        S: '0.150787221',
        P95: '27.91491246',
    },
    {
        Sex: '2',
        Agemos: '178.5',
        L: '-2.023123159',
        M: '19.83763907',
        S: '0.150700774',
        P95: '27.98509093',
    },
    {
        Sex: '2',
        Agemos: '179.5',
        L: '-2.028911755',
        M: '19.88429066',
        S: '0.150608788',
        P95: '28.05468167',
    },
    {
        Sex: '2',
        Agemos: '180.5',
        L: '-2.034893091',
        M: '19.9305662',
        S: '0.150511645',
        P95: '28.12369169',
    },
    {
        Sex: '2',
        Agemos: '181.5',
        L: '-2.041060881',
        M: '19.97645636',
        S: '0.150409731',
        P95: '28.19212851',
    },
    {
        Sex: '2',
        Agemos: '182.5',
        L: '-2.047408604',
        M: '20.02195192',
        S: '0.15030344',
        P95: '28.26000023',
    },
    {
        Sex: '2',
        Agemos: '183.5',
        L: '-2.05392949',
        M: '20.06704377',
        S: '0.150193169',
        P95: '28.32731547',
    },
    {
        Sex: '2',
        Agemos: '184.5',
        L: '-2.060616513',
        M: '20.11172291',
        S: '0.150079322',
        P95: '28.3940834',
    },
    {
        Sex: '2',
        Agemos: '185.5',
        L: '-2.067462375',
        M: '20.15598047',
        S: '0.149962308',
        P95: '28.46031375',
    },
    {
        Sex: '2',
        Agemos: '186.5',
        L: '-2.074459502',
        M: '20.19980767',
        S: '0.14984254',
        P95: '28.52601678',
    },
    {
        Sex: '2',
        Agemos: '187.5',
        L: '-2.081600029',
        M: '20.24319586',
        S: '0.149720441',
        P95: '28.59120331',
    },
    {
        Sex: '2',
        Agemos: '188.5',
        L: '-2.088875793',
        M: '20.28613648',
        S: '0.149596434',
        P95: '28.6558847',
    },
    {
        Sex: '2',
        Agemos: '189.5',
        L: '-2.096278323',
        M: '20.32862109',
        S: '0.149470953',
        P95: '28.72007286',
    },
    {
        Sex: '2',
        Agemos: '190.5',
        L: '-2.103798828',
        M: '20.37064138',
        S: '0.149344433',
        P95: '28.78378025',
    },
    {
        Sex: '2',
        Agemos: '191.5',
        L: '-2.111428194',
        M: '20.41218911',
        S: '0.149217319',
        P95: '28.84701989',
    },
    {
        Sex: '2',
        Agemos: '192.5',
        L: '-2.119156972',
        M: '20.45325617',
        S: '0.14909006',
        P95: '28.90980533',
    },
    {
        Sex: '2',
        Agemos: '193.5',
        L: '-2.126975375',
        M: '20.49383457',
        S: '0.14896311',
        P95: '28.97215071',
    },
    {
        Sex: '2',
        Agemos: '194.5',
        L: '-2.134873266',
        M: '20.5339164',
        S: '0.148836931',
        P95: '29.03407069',
    },
    {
        Sex: '2',
        Agemos: '195.5',
        L: '-2.142840157',
        M: '20.57349387',
        S: '0.148711989',
        P95: '29.0955805',
    },
    {
        Sex: '2',
        Agemos: '196.5',
        L: '-2.150865204',
        M: '20.61255929',
        S: '0.148588757',
        P95: '29.15669594',
    },
    {
        Sex: '2',
        Agemos: '197.5',
        L: '-2.158937201',
        M: '20.65110506',
        S: '0.148467715',
        P95: '29.21743336',
    },
    {
        Sex: '2',
        Agemos: '198.5',
        L: '-2.167044578',
        M: '20.6891237',
        S: '0.148349348',
        P95: '29.27780966',
    },
    {
        Sex: '2',
        Agemos: '199.5',
        L: '-2.175176987',
        M: '20.72660728',
        S: '0.14823412',
        P95: '29.33784232',
    },
    {
        Sex: '2',
        Agemos: '200.5',
        L: '-2.183317362',
        M: '20.76355011',
        S: '0.148122614',
        P95: '29.39754939',
    },
    {
        Sex: '2',
        Agemos: '201.5',
        L: '-2.191457792',
        M: '20.79994337',
        S: '0.148015249',
        P95: '29.45694948',
    },
    {
        Sex: '2',
        Agemos: '202.5',
        L: '-2.199583649',
        M: '20.83578051',
        S: '0.147912564',
        P95: '29.51606178',
    },
    {
        Sex: '2',
        Agemos: '203.5',
        L: '-2.207681525',
        M: '20.87105449',
        S: '0.147815078',
        P95: '29.57490604',
    },
    {
        Sex: '2',
        Agemos: '204.5',
        L: '-2.215737645',
        M: '20.90575839',
        S: '0.147723315',
        P95: '29.63350259',
    },
    {
        Sex: '2',
        Agemos: '205.5',
        L: '-2.223739902',
        M: '20.93988477',
        S: '0.147637768',
        P95: '29.69187236',
    },
    {
        Sex: '2',
        Agemos: '206.5',
        L: '-2.231667995',
        M: '20.97342858',
        S: '0.147559083',
        P95: '29.75003682',
    },
    {
        Sex: '2',
        Agemos: '207.5',
        L: '-2.239511942',
        M: '21.00638171',
        S: '0.147487716',
        P95: '29.80801808',
    },
    {
        Sex: '2',
        Agemos: '208.5',
        L: '-2.247257081',
        M: '21.0387374',
        S: '0.14742421',
        P95: '29.8658388',
    },
    {
        Sex: '2',
        Agemos: '209.5',
        L: '-2.254885145',
        M: '21.07048996',
        S: '0.147369174',
        P95: '29.92352223',
    },
    {
        Sex: '2',
        Agemos: '210.5',
        L: '-2.26238209',
        M: '21.10163241',
        S: '0.147323144',
        P95: '29.98109225',
    },
    {
        Sex: '2',
        Agemos: '211.5',
        L: '-2.269731517',
        M: '21.13215845',
        S: '0.147286698',
        P95: '30.03857329',
    },
    {
        Sex: '2',
        Agemos: '212.5',
        L: '-2.276917229',
        M: '21.16206171',
        S: '0.147260415',
        P95: '30.09599042',
    },
    {
        Sex: '2',
        Agemos: '213.5',
        L: '-2.283925442',
        M: '21.1913351',
        S: '0.147244828',
        P95: '30.15336926',
    },
    {
        Sex: '2',
        Agemos: '214.5',
        L: '-2.290731442',
        M: '21.21997472',
        S: '0.147240683',
        P95: '30.21073618',
    },
    {
        Sex: '2',
        Agemos: '215.5',
        L: '-2.29732427',
        M: '21.24797262',
        S: '0.147248467',
        P95: '30.26811799',
    },
    {
        Sex: '2',
        Agemos: '216.5',
        L: '-2.303687802',
        M: '21.27532239',
        S: '0.14726877',
        P95: '30.32554216',
    },
    {
        Sex: '2',
        Agemos: '217.5',
        L: '-2.309799971',
        M: '21.30201933',
        S: '0.147302299',
        P95: '30.38303689',
    },
    {
        Sex: '2',
        Agemos: '218.5',
        L: '-2.315651874',
        M: '21.32805489',
        S: '0.147349514',
        P95: '30.44063073',
    },
    {
        Sex: '2',
        Agemos: '219.5',
        L: '-2.32121731',
        M: '21.35342563',
        S: '0.147411215',
        P95: '30.49835324',
    },
    {
        Sex: '2',
        Agemos: '220.5',
        L: '-2.326481911',
        M: '21.37812462',
        S: '0.147487979',
        P95: '30.55623433',
    },
    {
        Sex: '2',
        Agemos: '221.5',
        L: '-2.331428139',
        M: '21.40214589',
        S: '0.147580453',
        P95: '30.61430463',
    },
    {
        Sex: '2',
        Agemos: '222.5',
        L: '-2.336038473',
        M: '21.42548351',
        S: '0.147689289',
        P95: '30.67259539',
    },
    {
        Sex: '2',
        Agemos: '223.5',
        L: '-2.34029545',
        M: '21.44813156',
        S: '0.14781515',
        P95: '30.73113849',
    },
    {
        Sex: '2',
        Agemos: '224.5',
        L: '-2.344181703',
        M: '21.47008412',
        S: '0.147958706',
        P95: '30.78996648',
    },
    {
        Sex: '2',
        Agemos: '225.5',
        L: '-2.34768',
        M: '21.49133529',
        S: '0.148120633',
        P95: '30.84911253',
    },
    {
        Sex: '2',
        Agemos: '226.5',
        L: '-2.350773286',
        M: '21.51187918',
        S: '0.148301619',
        P95: '30.90861047',
    },
    {
        Sex: '2',
        Agemos: '227.5',
        L: '-2.353444725',
        M: '21.53170989',
        S: '0.148502355',
        P95: '30.96849476',
    },
    {
        Sex: '2',
        Agemos: '228.5',
        L: '-2.355677743',
        M: '21.55082155',
        S: '0.148723546',
        P95: '31.02880054',
    },
    {
        Sex: '2',
        Agemos: '229.5',
        L: '-2.35745607',
        M: '21.56920824',
        S: '0.148965902',
        P95: '31.08956357',
    },
    {
        Sex: '2',
        Agemos: '230.5',
        L: '-2.358763788',
        M: '21.58686406',
        S: '0.149230142',
        P95: '31.1508203',
    },
    {
        Sex: '2',
        Agemos: '231.5',
        L: '-2.359585369',
        M: '21.60378309',
        S: '0.149516994',
        P95: '31.21260782',
    },
    {
        Sex: '2',
        Agemos: '232.5',
        L: '-2.359905726',
        M: '21.61995939',
        S: '0.149827195',
        P95: '31.27496389',
    },
    {
        Sex: '2',
        Agemos: '233.5',
        L: '-2.359710258',
        M: '21.635387',
        S: '0.150161492',
        P95: '31.33792691',
    },
    {
        Sex: '2',
        Agemos: '234.5',
        L: '-2.358980464',
        M: '21.65006126',
        S: '0.150520734',
        P95: '31.40153631',
    },
    {
        Sex: '2',
        Agemos: '235.5',
        L: '-2.357714508',
        M: '21.6639727',
        S: '0.150905439',
        P95: '31.46583097',
    },
    {
        Sex: '2',
        Agemos: '236.5',
        L: '-2.355892424',
        M: '21.67711736',
        S: '0.151316531',
        P95: '31.53085181',
    },
    {
        Sex: '2',
        Agemos: '237.5',
        L: '-2.353501353',
        M: '21.68948935',
        S: '0.151754808',
        P95: '31.59663995',
    },
    {
        Sex: '2',
        Agemos: '238.5',
        L: '-2.350528726',
        M: '21.70108288',
        S: '0.152221086',
        P95: '31.66323724',
    },
    {
        Sex: '2',
        Agemos: '239.5',
        L: '-2.346962247',
        M: '21.71189225',
        S: '0.152716206',
        P95: '31.73068625',
    },
];
