import { useLocales } from 'hooks/locales';
import * as yup from 'yup';
export const useRegisterSchema = () => {
    const { trans } = useLocales();

    return yup.object().shape({
        academyName: yup
            .string()
            .required(trans('signup.validation.register.academyNameRequired'))
            .default(''),
        isMultiBranch: yup
            .boolean()
            .required(trans('signup.validation.register.isMultiBranchRequired'))
            .default(false),
        commercialRegistrationNumber: yup
            .string()
            .required(trans('signup.validation.register.commercialRegistrationNumberRequired'))
            .matches(
                /^\d{10}$/,
                trans('signup.validation.register.commercialRegistrationNumberInvalid'),
            )
            .default(''),
        academyContactNumber: yup
            .string()
            .required(trans('signup.validation.register.academyContactNumberRequired'))
            .matches(/^(05)\d{8}$/, trans('signup.validation.register.academyContactNumberInvalid'))
            .default(''),
        adminName: yup
            .string()
            .required(trans('signup.validation.register.adminNameRequired'))
            .default(''),
        adminEmail: yup
            .string()
            .required(trans('signup.validation.register.adminEmailRequired'))
            .matches(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                trans('signup.validation.register.adminEmailInvalid'),
            )
            .default(''),
        password: yup
            .string()
            .required(trans('signup.validation.register.passwordRequired'))
            .matches(/.{6,}$/, trans('signup.validation.register.passwordMinLength'))
            .default(''),
        // .matches(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        //     trans('validation.register.passwordComplexity'),
        // ),
    });
};
