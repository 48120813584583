export const ItemTypes = {
    ELEMENT: 'element',
    PLAYER: 'player',
    ARROW: 'arrow',
    FOOTBALL: 'football',
    LADDER: 'ladder',
    CONE: 'cone',
    SQUARE: 'square',
    PYRAMID: 'pyramid',
    GOAL: 'goal',
};
