export enum UserRole {
    ADMIN = 'admin',
    ACADEMY_ADMIN = 'academy-admin',
    ACADEMY_COORDINATOR = 'academy-coordinator',
    MEMBER = 'member',
    COACH = 'coach',
    ADMIN_MANAGER = 'admin-manager',
}

export enum Language {
    ENGLISH = 'english',
    ARABIC = 'arabic',
}
