import styled from 'styled-components';

export const Body = styled.div.attrs({
    className: 'relative',
})`
    width: 100%;
`;

export const Icon = styled.img.attrs({
    className: '',
})``;
