import styled, { css } from 'styled-components';
import { Text as DefaultText, Image as DefaultImage } from 'components';

export const Body = styled.header.attrs({ className: 'relative flex gap-3 items-center' })`
    padding: 25px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export const Crump = styled(DefaultText)<{ $isLast: boolean }>`
    transition: opacity 0.3s ease;
    font-size: 20px;
    font-weight: 500;
    cursor: ${(props) => (props.$isLast ? 'normal' : 'pointer')};

    &:hover {
        opacity: ${(props) => (props.$isLast ? 1 : 0.6)};
    }
`;

export const Arrow = styled(DefaultImage)<{ $isRTL?: boolean }>`
    width: auto;
    height: 15px;
    margin: 0 10px;

    ${({ $isRTL }) => {
        if ($isRTL)
            return css`
                transform: scaleX(-1);
            `;

        return css``;
    }}
`;
